export default function Header({ className, page }: any) {

    return (
        <div className="w-full bg-white ring-1 ring-gray-900/5">
            <header className={`mx-auto bg-white w-full md:w-[1024px] flex flex-col shadow-sm ${className ?? ''}`}>

                <div className='mx-auto h-[60px] w-full md:w-[1024px] px-8 py-2 flex flex-row items-center justify-center'>

                    <a href="/"><span className='font-bold font-bold'>KPTD</span>.ph</a>

                    <div className="hidden md:block grow">
                        <ul className={`flex flex-row items-center text-sm`}>
                            <div className="grow"></div>
                            <li><a href='/pricing' className={`rounded-full px-5 py-2 hover:bg-black border-2 hover:text-white hover:border-transparent ${page === 'pricing' ? 'border-blue-600' : 'border-transparent'}`}>Pricing</a></li>

                            <li><a href='/servers' className={`rounded-full px-5 py-2 hover:bg-black border-2 hover:text-white hover:border-transparent ${page === 'servers' ? 'border-blue-600' : 'border-transparent'}`}>Servers</a></li>

                            <li><a href='/tutorials' className={`rounded-full px-5 py-2 hover:bg-black border-2 hover:text-white hover:border-transparent ${page === 'tutorials' ? 'border-blue-600' : 'border-transparent'}`}>Tutorials</a></li>

                            <li className="mr-3"><a href='/contact' className={`rounded-full px-5 py-2 border-2 hover:bg-black hover:text-white hover:border-transparent ${page === 'contact' ? 'border-blue-600' : 'border-transparent'}`}>Contact</a></li>

                            <li><a href='/signin' className='bg-blue-600 text-white rounded-full px-5 py-2 hover:bg-black'>Sign In</a></li>
                        </ul>
                    </div>
                </div>

                <div className="md:hidden w-full divide-y-8 bg-white">
                    <ul className='flex flex-row h-[40px] items-center text-xs justify-center'>

                        <li><a href='/pricing' className={`rounded-full px-3 py-1 hover:bg-black border-2 hover:text-white hover:border-transparent ${page === 'pricing' ? 'border-blue-600' : 'border-transparent'}`}>Pricing</a></li>

                        <li><a href='/servers' className={`rounded-full px-3 py-1 hover:bg-black border-2 hover:text-white hover:border-transparent ${page === 'servers' ? 'border-blue-600' : 'border-transparent'}`}>Servers</a></li>

                        <li><a href='/tutorials' className={`rounded-full px-3 py-1 hover:bg-black border-2 hover:text-white hover:border-transparent ${page === 'tutorials' ? 'border-blue-600' : 'border-transparent'}`}>Tutorials</a></li>

                        <li className="mr-3"><a href='/contact' className={`rounded-full px-3 py-1 border-2 hover:bg-black hover:text-white hover:border-transparent ${page === 'contact' ? 'border-blue-600' : 'border-transparent'}`}>Contact</a></li>

                        <li><a href='/signin' className='bg-blue-600 text-white rounded-full px-3 py-1 hover:bg-black'>Sign In</a></li>
                    </ul>
                </div>
            </header>
        </div>
    )
}
