import { useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import ReactPlayer from 'react-player/youtube'
import Spinner from "../../../components/spinner";
import TutorialNav from "../components/tutorial_nav";
import { IoArrowBackCircle } from "react-icons/io5";
import moment from "moment";
import 'moment-timezone';

export default function HowToInstallOvpnClientOnAdopisoft() {

    const [loading, setLoading] = useState(true);

    const date = new Date();// moment(new Date()).format('MMMM DD YYYY hh:mm a');

    return (
        <>
            <div className="flex flex-col h-screen">
                <Header className='flex' />
                <div className="flex-1 mb-5 lg:my-10 w-full">
                    <div className='mx-auto w-full lg:max-w-[1024px] px-5'>

                        <div className='mx-auto text-center mx-auto w-full lg:w-[500px] mb-10'>
                            <h1 className='mt-10 mb-2 text-[30px] leading-tight'>Tutorials</h1>
                            <p>Get started with our service through simple, guided tutorials and make the most of its features.</p>
                        </div>

                        <div className="mx-auto bg-white rounded-xl p-8 flex flex-row gap-5">

                            <TutorialNav name='ovpn-adopisoft' />

                            <div className="hidden lg:block h-screen min-h-full w-[0.5px] self-stretch bg-gray-300"></div>

                            <div className="grow lg:ml-5 w-[600px]">

                                <div className="flex flex-row items-center gap-3">
                                    <IoArrowBackCircle size={30} className="lg:hidden text-blue-500 cursor-pointer"
                                        onClick={() => {
                                            window.location.replace('/tutorials');
                                        }}
                                    />
                                    <h1 className="text-xl font-bold">How to install OVPN client on Adopisoft</h1>
                                </div>

                                <div className="mt-5 mb-10 relative bg-gray-900 h-[360px] w-full">

                                    <div className="w-full h-[360px]">
                                        <ReactPlayer style={{ margin: '0px auto' }} width='100%' /*height={505} width={853}*/ onReady={() => {
                                            setLoading(false);
                                        }} controls={true} url='https://www.youtube.com/watch?v=LK0VUIMml8E' />
                                    </div>
                                    {loading && <div className="absolute inset-0 flex justify-center items-center w-full h-[360px]"><Spinner className='h-[50px] w-[50px] text-white' /></div>}
                                </div>

                                <hr className="border-2" />

                                <div className="mt-5 mb-5 ml-5">
                                    <div className="font-medium">Table Of Contents</div>
                                    <ul className="list-disc flex flex-col ml-5 gap-1 text-sm">
                                        <li>
                                            <button className="text-blue-500 underline" onClick={() => window.location.replace("/tutorials/how-to-install-ovpn-client-on-adopisoft#step1")}>Enable Adopisoft SSH</button>
                                        </li>
                                        <li>
                                            <button className="text-blue-500 underline" onClick={() => window.location.replace("/tutorials/how-to-install-ovpn-client-on-adopisoft#step2")}>Opening Adopisoft SSH</button>
                                        </li>
                                        <li>
                                            <button className="text-blue-500 underline" onClick={() => window.location.replace("/tutorials/how-to-install-ovpn-client-on-adopisoft#step3")}>Check Date and Time</button>
                                        </li>
                                        <li>
                                            <button className="text-blue-500 underline" onClick={() => window.location.replace("/tutorials/how-to-install-ovpn-client-on-adopisoft#step4")}>Set Date and Time</button>
                                        </li>
                                        <li>
                                            <button className="text-blue-500 underline" onClick={() => window.location.replace("/tutorials/how-to-install-ovpn-client-on-adopisoft#step5")}>Copy OVPN installer file url</button>
                                        </li>
                                        <li>
                                            <button className="text-blue-500 underline" onClick={() => window.location.replace("/tutorials/how-to-install-ovpn-client-on-adopisoft#step6")}>Download OVPN installer file on Adopisoft SSH</button>
                                        </li>
                                        <li>
                                            <button className="text-blue-500 underline" onClick={() => window.location.replace("/tutorials/how-to-install-ovpn-client-on-adopisoft#step7")}>Add execution permission to the OVPN installer file</button>
                                        </li>
                                        <li>
                                            <button className="text-blue-500 underline" onClick={() => window.location.replace("/tutorials/how-to-install-ovpn-client-on-adopisoft#step8")}>Install OVPN installer</button>
                                        </li>
                                        <li>
                                            <button className="text-blue-500 underline" onClick={() => window.location.replace("/tutorials/how-to-install-ovpn-client-on-adopisoft#step9")}>Bridge the OVPN interface from the Adopisoft admin dashboard</button>
                                        </li>
                                    </ul>
                                </div>

                                <hr className="border-2" />

                                <ul className="list-decimal ml-5 mt-8 flex flex-col gap-8">
                                    <li id="step1">
                                        <div className="font-bold text-lg mb-2">Enable Adopisoft SSH</div>
                                        <ul className="ml-2 mb-3 list-disc flex flex-col gap-2">
                                            <li>Login to your Adopisoft admin dashboard</li>
                                            <li>Go to <span className="font-bold">System</span> {">"} <span className="font-bold">System Services</span> then click <span className="font-bold">Enable SSH</span></li>
                                        </ul>
                                        <img src="https://cloud.appwrite.io/v1/storage/buckets/66b1f4b6001788eef4ac/files/66e6629400396ad80d5b/view?project=66ab269e0028c6434b2e" alt="" />
                                    </li>
                                    <li id="step2">
                                        <div className="font-bold text-lg mb-2">Opening Adopisoft SSH</div>
                                        <ul className="ml-2 list-disc flex flex-col gap-2">
                                            <li>Open <span className="font-bold">command prompt</span> window for Windows OS</li>
                                            <li >
                                                <div className="">In the command prompt window type the following and press enter:</div>
                                                <div className="mb-2 text-xs italic">Note: replace <span className="font-bold">[adopisoft ip address]</span> with the ip address of your adopisoft</div>

                                                <ul className="mb-3 flex flex-col gap-2 ml-3">
                                                    <li className="flex flex-row items-center gap-2">
                                                        <span>For Raspberry PI:</span>
                                                        <span className="bg-black text-white rounded-md px-2 font-medium tracking-wider">ssh pi@[adopisoft ip address]</span>
                                                    </li>
                                                    <li className="flex flex-row items-center gap-2">
                                                        <span>For Orange PI:</span>
                                                        <span className="bg-black text-white rounded-md px-2 font-medium tracking-wider">ssh root@[adopisoft ip address]</span>
                                                    </li>
                                                </ul>
                                                <img src="https://cloud.appwrite.io/v1/storage/buckets/66b1f4b6001788eef4ac/files/66e6714d00111c028d0e/view?project=66ab269e0028c6434b2e" alt="" />

                                            </li>

                                            <li>
                                                <div className="mb-3">If this is your first time opening Adopisoft SSH on your PC or laptop, you will see a prompt similar to the one in the screenshot bellow. Type <span className="font-bold">yes</span> and press Enter.</div>
                                                <img src="https://cloud.appwrite.io/v1/storage/buckets/66b1f4b6001788eef4ac/files/66e671bd002c96d6d088/view?project=66ab269e0028c6434b2e" alt="" />
                                            </li>

                                            <li>
                                                <div className="">Type the Adopisoft SSH password and press enter.</div>
                                                <div className="mb-2 text-xs italic">Note: you won't see what you are typing in the command prompt window</div>
                                                <ul className="ml-3 flex flex-col gap-2">
                                                    <li className="flex flex-row items-center gap-2">
                                                        <span>Password for Raspberry PI:</span>
                                                        <span className="bg-black text-white rounded-md px-2 font-medium tracking-wider">raspberry</span>
                                                    </li>
                                                    <li className="flex flex-row items-center gap-2">
                                                        <span>Password for Orange PI:</span>
                                                        <span className="bg-black text-white rounded-md px-2 font-medium tracking-wider">asdf@12345</span>
                                                    </li>
                                                </ul>
                                                <div className="mt-3 text-xs italic">For other devices or operating systems, please visit the Adopisoft documentation website.</div>
                                            </li>

                                            <li>
                                                <div className="mb-3">If you successfully connect to your Adopisoft SSH, you will see a prompt similar to the one in the screenshot bellow. You may proceed to the next step.</div>
                                                <img src="https://cloud.appwrite.io/v1/storage/buckets/66b1f4b6001788eef4ac/files/66e675c50032916dc7e5/view?project=66ab269e0028c6434b2e" alt="" />
                                            </li>
                                        </ul>
                                    </li>

                                    <li id="step3">
                                        <div className="font-bold text-lg mb-2">Check Date and Time</div>
                                        <ul className="list-disc ml-2">
                                            <li>
                                                <div className="mb-3">In the command prompt window type <span className="bg-black text-white rounded-md px-2 font-medium tracking-wider">date</span> and press enter.</div>
                                                <img src="https://cloud.appwrite.io/v1/storage/buckets/66b1f4b6001788eef4ac/files/66e6776d00179c614b2f/view?project=66ab269e0028c6434b2e" alt="" />
                                            </li>
                                            <li>
                                                <div className="mt-3">If the date and time are set correctly, proceed to Step 5.</div>
                                            </li>
                                        </ul>


                                    </li>

                                    <li id="step4">
                                        <div className="font-bold text-lg mb-2">Set Date and Time</div>
                                        {/* <div className="mb-2 text-xs italic">You can skip this step and proceed to Step 5 if the date and time of your Adopisoft SSH are set correctly.</div> */}

                                        <ul className="list-disc ml-2">
                                            <li>
                                                <div className="">In the command prompt window type <span className="bg-black text-white rounded-md px-2 font-medium tracking-wider">sudo date [date and time]</span> and press enter.</div>
                                                <div className="mb-2 text-xs italic">Note: Change the <span className="font-bold">[date and time]</span> with the correct current date and time. Follow the format bellow.</div>

                                                <div className="ml-3">
                                                    <div className="flex flex-row items-center gap-2"><span className="text-sm w-[100px]">Example:</span><span className="font-bold">{moment(date).format('MMMM DD YYYY hh:mm a')}</span></div>
                                                    <div className="flex flex-row items-center gap-2 mb-1"><span className="text-sm w-[100px]">Format:</span><span className="font-bold">{moment(date).tz('Asia/Manila').format('MMDDHHmmYYYY')}</span></div>
                                                    <div className="flex flex-row items-center gap-2 mb-1"><span className="text-sm">Format Breakdown:</span></div>
                                                    <div className="flex flex-col gap-1 text-sm ml-[110px]">
                                                        <div className="flex flex-row  items-center gap-1">
                                                            <div className="w-[50px] font-bold">{moment(date).tz('Asia/Manila').format('MM')}</div>
                                                            <div>{moment(date).format('MMMM')}</div>
                                                            <div className="text-xs">(month)</div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col gap-1 text-sm ml-[110px]">
                                                        <div className="flex flex-row  items-center gap-1">
                                                            <div className="w-[50px] font-bold">{moment(date).tz('Asia/Manila').format('DD')}</div>
                                                            <div>{moment(date).format('DD')}</div>
                                                            <div className="text-xs">(day)</div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col gap-1 text-sm ml-[110px]">
                                                        <div className="flex flex-row items-center gap-1">
                                                            <div className="w-[50px] font-bold">{moment(date).tz('Asia/Manila').format('HH')}</div>
                                                            <div>{moment(date).format('hh')}</div>
                                                            <div className="text-xs">(military time for hour)</div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col gap-1 text-sm ml-[110px]">
                                                        <div className="flex flex-row items-center gap-1">
                                                            <div className="w-[50px] font-bold">{moment(date).tz('Asia/Manila').format('mm')}</div>
                                                            <div>{moment(date).format('mm')}</div>
                                                            <div className="text-xs">(minute)</div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col gap-1 text-sm ml-[110px]">
                                                        <div className="flex flex-row items-center gap-1">
                                                            <div className="w-[50px] font-bold">{moment(date).tz('Asia/Manila').format('YYYY')}</div>
                                                            <div>{moment(date).format('YYYY')}</div>
                                                            <div className="text-xs">(year)</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="mt-5 mb-3">You should type <span className="bg-black text-white rounded-md px-2 font-medium tracking-wider">sudo date {moment(date).tz('Asia/Manila').format('MMDDHHmmYYYY')}</span> in the command prompt window and press enter.</div>
                                                <img src="https://cloud.appwrite.io/v1/storage/buckets/66b1f4b6001788eef4ac/files/66e681370019e86c3a7f/view?project=66ab269e0028c6434b2e" alt="" />
                                            </li>

                                            <li>
                                                <div className="mt-3">To verify that you have set the date and time correctly, go back to Step 3.</div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li id="step5">
                                        <div className="font-bold text-lg mb-2">Copy OVPN installer file url</div>
                                        <ul className="ml-2 list-disc">

                                            <li>
                                                <div className="mb-3">Login to your KPTD account. <a href="/signin" target="_blank" className="text-sm text-blue-500">Sign In</a></div>
                                            </li>
                                            <li>
                                                <div className="mb-3">Navigate to <span className="font-bold">Remote Access</span> in the left navigation menu.</div>
                                            </li>
                                            <li>
                                                <div className="mb-3">Choose your remote access account with <span className="font-bold">OVPN</span> service and <span className="font-bold">HTTPS</span> or <span className="font-bold">443</span> in Remote URL.</div>
                                            </li>
                                            <li>
                                                <div className="mb-3">Click on the name of the remote access account and you will find the Adopisoft OVPN url installer.</div>
                                            </li>
                                            <li>
                                                <div className="mb-3">Click the copy icon or highlight the URL of the installer and copy it.</div>
                                            </li>

                                            <img src="https://cloud.appwrite.io/v1/storage/buckets/66b1f4b6001788eef4ac/files/66e688cf00369f688c19/view?project=66ab269e0028c6434b2e" alt="" />

                                            <li>
                                                <div className="mt-3">To verify the installer file, paste the copied URL into the address bar of Google Chrome.</div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li id="step6">
                                        <div className="font-bold text-lg mb-2">Download OVPN installer file on Adopisoft SSH</div>
                                        <ul className="ml-2 list-disc">
                                            <li>
                                                <div className="">In the command prompt window type <span className="bg-black text-white rounded-md px-2 font-medium tracking-wider">curl -O [url]</span> and press enter.</div>
                                                <div className="mb-2 text-xs italic">Note: Changed the <span className="font-bold">[url]</span> with your remote access account installer url by pasting the copied url.</div>
                                                <div className="my-3">
                                                    <div className="flex flex-row items-center gap-2"><span className="text-sm w-[100px]">Example:</span><span className="bg-black text-white rounded-md px-2 font-medium tracking-wider">curl -O https://dl.kptd.ph/xxxxxx/ovpn.sh</span></div>
                                                </div>
                                                <img src="https://cloud.appwrite.io/v1/storage/buckets/66b1f4b6001788eef4ac/files/66e68bd3000b5d13ae28/view?project=66ab269e0028c6434b2e" alt="" />
                                            </li>
                                            <li>
                                                <div className="my-3">To verify that the installer file has downloaded successfully, type <span className="bg-black text-white rounded-md px-2 font-medium tracking-wider">ls</span> (small letter "L", small letter "S") in the command prompt window and press Enter. You should see the <span className="font-bold">ovpn.sh</span> file listed.</div>
                                                <img src="https://cloud.appwrite.io/v1/storage/buckets/66b1f4b6001788eef4ac/files/66e68d180005bb8f8110/view?project=66ab269e0028c6434b2e" alt="" />
                                            </li>
                                        </ul>
                                    </li>

                                    <li id="step7">
                                        <div className="font-bold text-lg mb-2">Add execution permission to the OVPN installer file</div>
                                        <ul className="ml-2 list-disc">
                                            <li>
                                                <div className="mb-3">In the command prompt window type <span className="bg-black text-white rounded-md px-2 font-medium tracking-wider">chmod +x ovpn.sh</span> and press enter.</div>
                                                <img src="https://cloud.appwrite.io/v1/storage/buckets/66b1f4b6001788eef4ac/files/66e68e0a00134dfc25ae/view?project=66ab269e0028c6434b2e" alt="" />
                                            </li>
                                            <li>
                                                <div className="my-3">To verify that you have successfully added the execution permission to the installer file, you should see the <span className="font-bold text-green-500">ovpn.sh</span> display in green when you type <span className="bg-black text-white rounded-md px-2 font-medium tracking-wider">ls</span> again in the command prompt and press enter.</div>
                                                <img src="https://cloud.appwrite.io/v1/storage/buckets/66b1f4b6001788eef4ac/files/66e68f3c0032459a63c3/view?project=66ab269e0028c6434b2e" alt="" />
                                            </li>
                                        </ul>
                                    </li>

                                    <li id="step8">
                                        <div className="font-bold text-lg mb-2">Install OVPN installer</div>
                                        <ul className="ml-2 list-disc">
                                            <li>
                                                <div className="mb-3">In the command prompt window type <span className="bg-black text-white rounded-md px-2 font-medium tracking-wider">./ovpn.sh</span> and press enter.</div>
                                                <img src="https://cloud.appwrite.io/v1/storage/buckets/66b1f4b6001788eef4ac/files/66e69070001a488723e6/view?project=66ab269e0028c6434b2e" alt="" />
                                                <div className="my-3 text-sm italic text-blue-500">Note: If the installer file content has downloaded correctly, you should see a prompt similar to the one shown on the screenshot above.</div>
                                            </li>
                                            <li>
                                                <div className="mb-3">To proceed with the installation, type <span className="font-bold">y</span> and press enter.</div>
                                            </li>
                                            <li>
                                                <div className="mb-3">Wait until the installation is complete.</div>
                                            </li>
                                            <li>
                                                <div className="mb-3">If you do not encounter any errors after the installation is complete, you should see a result similar to the image below.</div>
                                                <img src="https://cloud.appwrite.io/v1/storage/buckets/66b1f4b6001788eef4ac/files/66e69269002328dacf16/view?project=66ab269e0028c6434b2e" alt="" />
                                            </li>
                                            <li>
                                                <div className="my-3">You can now access your Adopisoft remotely by copying the remote URL displayed in the command prompt window.</div>
                                                <div className="text-red-500 font-medium">Note: You need to bridge the VPN interface of the remote access VPN. Proceed to the next step</div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li id="step9">
                                        <div className="font-bold text-lg mb-2">Bridge the OVPN interface from the Adopisoft admin dashboard</div>
                                        <ul className="ml-2 list-disc flex flex-col gap-2">
                                            <li>
                                                Login to your Adopisoft admin dashboard
                                            </li>
                                            <li>
                                                <div>Go to <span className="font-bold">Network</span> {">"} <span className="font-bold">Bridges</span></div>
                                            </li>
                                            <li>
                                                <div>Scroll down until you see <span className="font-bold">Select Ports/Interfaces to bridge:</span></div>
                                            </li>
                                            <li>
                                                <div>Check the newly added VPN interface named <span className="font-bold">tun0</span></div>
                                            </li>
                                            <li>
                                                <div className="mb-3">Save the changes and reboot your Adopisoft</div>
                                                <img src="https://cloud.appwrite.io/v1/storage/buckets/66b1f4b6001788eef4ac/files/66e69582000b055a96c6/view?project=66ab269e0028c6434b2e" alt="" />
                                            </li>
                                        </ul>
                                    </li>

                                </ul>


                                <div className="text-center mt-10">If you encounter any issues during installation, please contact <a href="mailto:support@kptd.ph" className="text-blue-500">support@kptd.ph</a> and attach a screenshot of the error. Alternatively, you can use our contact form by clicking <a href="/contact" className="text-blue-500">here</a>.</div>


                            </div>

                        </div>

                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}
