
export default function TutorialNav({ name, className }: any) {

    return (
        <div className={`w-[250px] hidden lg:block ${className && className}`}>
            <div className="font-medium text-lg mb-3">Navigation</div>
            <ul className="text-sm ml-5 list-disc flex flex-col gap-3">
                {
                    navList.map((item: any, index: number) => {
                        return <li key={`${index}-nav`}>
                            <a href={item.url} className={`text-blue-500 underline ${name === item.name && 'font-bold'}`}>{item.title}</a>
                        </li>
                    })
                }

            </ul>
        </div>
    )
}

const navList = [
    { name: 'home', title: 'Tutorials Home', url: '/tutorials' },
    { name: 'l2tp', title: 'Configuring L2TP Client on MikroTik', url: '/tutorials/how-to-connect-l2tp-client' },
    { name: 'sstp', title: 'Configuring SSTP Client on MikroTik', url: '/tutorials/how-to-connect-sstp-client' },
    { name: 'ovpn', title: 'Configuring OVPN Client on MikroTik', url: '/tutorials/how-to-connect-ovpn-client' },
    { name: 'connect', title: 'How to remotely access MikroTik using Winbox', url: '/tutorials/how-to-remotely-access-mikrotik-using-winbox' },
    { name: 'ovpn-adopisoft', title: 'How to install OVPN client on Adopisoft', url: '/tutorials/how-to-install-ovpn-client-on-adopisoft' }
];