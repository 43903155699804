import PlainNotFound from '../404/404_plain'
import PaymentSuccess from './success';
import PaymentFailed from './failed';
import PaymentCanceled from './canceled';

export default function Payment() {

    const search = new URLSearchParams(window.location.search);
    const status = search.get('status');

    switch (status) {
        case 'success':
            return (<PaymentSuccess />);
        case 'failed':
            return (<PaymentFailed />);
        case 'canceled':
            return (<PaymentCanceled />);
    }

    return (<PlainNotFound />)
}
