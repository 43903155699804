import { useEffect } from 'react';
import { CiCircleCheck } from 'react-icons/ci';

export default function PaymentSuccess() {

  useEffect(()=>{
    setTimeout(()=> {
      window.close();
    }, 5000);
  });
  
  return (<div className='w-screen h-screen text-center flex flex-col bg-white justify-center'>
    <div className='text-center w-full'>

      <div className='bg-green-100 h-[130px] w-[130px] rounded-full mx-auto text-center items-center justify-center flex flex-row'>
        <CiCircleCheck size={100} className='text-green-500' />
      </div>
      <div className='text-lg mt-5'>Payment Success!</div>
      <div className='mt-5 mb-10 mx-auto px-5 text-sm w-[400px]'>
        Your payment has been processed successfully. Thank you for your purchase!
        <br />
        You can now close this window and return to the previous page.
      </div>
      <a href='/' className='font-medium text-blue-500'>KPTD.ph</a>
    </div>
  </div>);
}
