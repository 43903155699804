import { useEffect, useState } from "react";
import Header from "../../components/header";
import { RiSendPlane2Fill } from "react-icons/ri";
import Spinner from "../../components/spinner";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { CheckIcon } from '@heroicons/react/24/outline'
import Footer from "../../components/footer";
import { errorMessage } from "../../utils/utils";
import api from "../../utils/api";

const connectionLimit: string = "500 Kbps";
const daysExpiration: string = "7 days";

export default function Demo(props: any) {

    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [service, setService] = useState('');
    const [server, setServer] = useState('');
    const [app_port, setAppPort] = useState('');

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [open, setOpen] = useState(false);
    const [accept, setAccept] = useState(false);

    const [serverLoading, setServerLoading] = useState(true);
    const [servers, setServers] = useState<any>([]);
    const [services, setServices] = useState<any>([]);

    async function handleSubmit(e: any) {
        e.preventDefault();

        setError('');
        setSuccess('');
        setLoading(true);

        try {

            const res = await api.post(`/remote/demo`, { email, service, server, app_port, accept });

            const json = await res.json();

            if (res.ok) {
                setSuccess(json.message);
                setOpen(true);
            } else {
                setError(errorMessage(json))
            }
        } catch (err) {
            setError(errorMessage(err))
        }

        setLoading(false);
    }

    async function getServers() {
        try {
            const res = await api.get(`/remote/server?subs=demo`);
            const json = await res.json();
            if (res.ok) {
                setServers(json);
                if (json.length > 0) {
                    const find = json.find((item: any) => item.enabled === true);
                    if (find) {
                        setServer(find.name);
                        setServices(find.services);
                        setService(find.services[0]);
                        setAppPort('8291');
                    }
                }
            } else {
                setError(errorMessage(json));
            }
        } catch (err) {
            setError(errorMessage(err));
        }

        setServerLoading(false);
    }


    useEffect(() => {
        getServers();
    }, []);

    return (
        <>
            <div className="flex flex-col h-screen">
                <Header className='flex' />

                <div className="flex-1 my-10">

                    <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Get A Free Demo</h2>

                    <div className="text-center text-sm">We will send the information to the email address you provide.<br />Please enter a valid email address.</div>

                    <div className="mx-auto px-10 max-w-[500px] mt-10">

                        <div className="font-medium">Free demo is limited to:</div>

                        <ul className="text-sm mt-2 ml-10 list-disc flex flex-col gap-1">
                            <li>Only one remote connection per IP is allowed per request.</li>
                            <li>Only one remote connection per email is allowed.</li>
                            <li>Connection bandwidth is limited to {connectionLimit}</li>
                            <li>Expires in {daysExpiration}</li>
                        </ul>

                    </div>

                    <div className="px-2 text-red-500 text-center mt-8 text-sm font-medium">For security reasons, we only allow one demo account per IP address</div>

                    <div className="mt-5 mx-auto max-w-[500px]">

                        <div className="bg-white px-8 sm:px-12 pb-12 pt-8 shadow rounded-lg">

                            <form onSubmit={handleSubmit}>

                                <div className="mb-3">
                                    <label htmlFor="server" className="block text-sm font-medium leading-6 text-gray-900 mb-1">Server</label>
                                    <div className="relative">
                                        {serverLoading && <Spinner className='absolute ml-2 mt-[5px]' />}
                                        <select
                                            name='server'
                                            value={server}
                                            required={true}
                                            disabled={loading || serverLoading}
                                            className={`w-full h-8 px-2 py-1 border border-gray-300 text-sm rounded focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50`}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value) {
                                                    const find = servers.find((item: any) => item.name === value);
                                                    if (find) {
                                                        setServices(find.services);
                                                        setService(find.services[0]);
                                                    }
                                                    setAppPort('8291');
                                                } else {
                                                    setServices([]);
                                                    setService('');
                                                    setAppPort('');
                                                }
                                                setServer(value);
                                            }}>
                                            {!serverLoading && <option value=''>Please select...</option>}
                                            {
                                                servers.map((item: any, index: number) => {
                                                    return <option key={index} value={item.name} disabled={!item.enabled}>{item.label ?? item.name}{!item.enabled && ' (unavailable)'}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="flex flex-row items-center gap-5 mb-5">
                                    <div className="grow">
                                        <label htmlFor="service" className="block text-sm font-medium leading-6 text-gray-900 mb-1">Service</label>
                                        <select
                                            name='service'
                                            value={service}
                                            required={true}
                                            disabled={loading || !server}
                                            className={`w-full h-8 px-2 py-1 border border-gray-300 text-sm rounded focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50`}
                                            onChange={(e) => {
                                                setService(e.target.value)
                                            }}>
                                            <option value=''>Please select...</option>
                                            {
                                                services.map((item: any, index: number) => {
                                                    return <option key={`${index}-service`} value={item}>{item.toUpperCase()}</option>
                                                })
                                            }
                                        </select>
                                    </div>

                                    <div className="grow">
                                        <label htmlFor="application" className="block text-sm font-medium leading-6 text-gray-900 mb-1">Application</label>
                                        <select
                                            name='application'
                                            value={app_port}
                                            required={true}
                                            disabled={loading || !server}
                                            className={`w-full h-8 px-2 py-1 border border-gray-300 text-sm rounded focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50`}
                                            onChange={(e) => setAppPort(e.target.value)}>
                                            <option value=''>Please select...</option>
                                            <option value='8291'>Winbox (8291)</option>
                                            <option value='80' disabled={true}>HTTP (80)</option>
                                            <option value='443' disabled={true}>HTTPS (443)</option>
                                            <option value='22' disabled={true}>SSH (22)</option>
                                            <option value='custom' disabled={true}>Custom</option>
                                        </select>
                                    </div>

                                </div>

                                <div className="mb-5">
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 mb-1">
                                        Email address
                                    </label>
                                    <div>
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            required={true}
                                            value={email}
                                            disabled={loading}
                                            placeholder="name@domain.com"
                                            autoComplete="email"
                                            className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 disabled:bg-gray-50 placeholder:text-sm placeholder:text-gray-400"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-row items-center mb-5">
                                    <div className="grow"></div>
                                    <input id="link-checkbox" checked={accept} type="checkbox" onChange={loading ? undefined : (e) => setAccept(e.target.checked)} className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" />
                                    <label htmlFor="link-checkbox" className="ms-2 text-xs font-medium text-gray-700">I agree with the <a href="/terms-and-conditions" target="_blank" className="text-blue-600 hover:underline">Terms and Conditions</a> and <a href="/privacy-policy" target="_blank" className="text-blue-600 hover:underline">Privacy Policy</a>.</label>
                                    <div className="grow"></div>
                                </div>

                                {error && <div className="text-center text-red-500 italic text-sm mb-5"><div dangerouslySetInnerHTML={{ __html: error }} /></div>}

                                <div className="mt-10">

                                    <button
                                        type="submit"
                                        disabled={loading || !accept}
                                        className="disabled:bg-gray-400 flex flex-row items-center gap-2 w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    >
                                        {loading ? <Spinner /> : <RiSendPlane2Fill />}
                                        Get Demo
                                    </button>

                                </div>
                            </form>

                        </div>

                        <p className="mt-10 text-center text-sm text-gray-500">
                            Already have an account?{' '}
                            <a href="/signin" className="font-semibold leading-6 text-blue-600 hover:text-black">
                                Sign In
                            </a>
                        </p>
                    </div>

                </div>

                <Footer />
            </div>

            <Dialog open={open} onClose={() => { }} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-200 sm:mx-0 sm:h-10 sm:w-10">
                                        <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-700" />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Demo Sent
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-700"><div dangerouslySetInnerHTML={{ __html: success }} /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                {/* <button
                                    type="button"
                                    onClick={() => setOpen(false)}
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                >
                                    Deactivate
                                </button> */}
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => setOpen(false)}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    OKAY
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>

        </>

    )
}
