
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { FaRegUserCircle } from 'react-icons/fa';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { LiaSignOutAltSolid } from "react-icons/lia";
import { IoMenu } from 'react-icons/io5';

export default function UserHeader({ setNav, title, className }: any) {

    const [email, setEmail] = useState('');

    useEffect(() => {
        setEmail(window.localStorage.email);
    }, []);

    function signOut() {
        window.localStorage.removeItem('refresh');
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('email');
        window.localStorage.removeItem('name');
        window.localStorage.removeItem('id');
        window.location.replace('/signin');
    }

    return (
        <div className={`h-[45px] bg-white shadow-sm flex flex-row items-center pl-5 pr-1 ring-1 ring-gray-900/5 ${className && className}`}>
            <IoMenu size={30} className='xl:hidden mr-5 cursor-pointer' onClick={() => {
                if (typeof setNav === 'function') {
                    setNav(()=>{});
                }
            }} />
            <div className='font-medium text-lg'>{title}</div>
            <div className='grow'></div>

            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <MenuButton className="inline-flex w-full items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        <FaRegUserCircle />
                        <div className='hidden md:block font-medium'>{email}</div>
                    </MenuButton>
                </div>

                <MenuItems
                    transition
                    className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                    <div className="py-1">
                        <MenuItem>
                            <a
                                href="/password-reset"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                                <MdOutlineChangeCircle className='inline mr-2' /> Change Password
                            </a>
                        </MenuItem>

                        <MenuItem>
                            <button
                                onClick={() => signOut()}
                                className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                                    <LiaSignOutAltSolid className='inline mr-2' />Sign out
                            </button>
                        </MenuItem>
                    </div>
                </MenuItems>
            </Menu>

        </div>
    )
}
