


import { useEffect, useState } from 'react'
import Loader from '../../components/loader'
import { errorMessage } from '../../utils/utils';
import { CiCircleCheck } from 'react-icons/ci';
import { PiWarningCircleThin } from 'react-icons/pi';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import Spinner from '../../components/spinner';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import api from '../../utils/api';

export default function User() {

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const [loading, setLoading] = useState(false);

    const [isVerify, setIsVerify] = useState(false);
    const [isReset, setIsReset] = useState(false);

    const [password, setPassword] = useState('');
    const [showPass, setShowPass] = useState(false);


    useEffect(() => {

        const params = new URLSearchParams(window.location.search);

        const reset = params.get('reset');
        if (reset) {
            verifyReset(reset);
            return;
        }

        const verify = params.get('verify');
        if (verify) {
            verifyToken(verify);
            return;
        }

        const token = window.localStorage.token;

        if (token) {
            if (window.location.pathname === '/user') {
                window.location.replace(`/user/dashboard${window.location.search}`);
            }

        } else {
            window.location.replace(`/signin${window.location.search}`);
        }

    }, []);


    function goToPage(url: string) {
        window.localStorage.removeItem('refresh');
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('email');
        window.localStorage.removeItem('name');
        window.localStorage.removeItem('id');
        window.location.replace(url);
    }


    async function verifyToken(token: string) {

        try {
            const res = await api.post(`/auth/verify/${token}`);
            const json = await res.json();

            if (res.ok) {
                setSuccess(json.message);
            } else {
                setError(errorMessage(json));
            }

        } catch (err) {
            setError(errorMessage(err));
        }

        setIsVerify(true);
    }

    async function verifyReset(token: string) {

        try {
            const res = await api.post(`/auth/verify/${token}`);
            const json = await res.json();

            if (res.ok) {
                if (json.ok === true) {
                    setError('');
                    setIsReset(true);
                    return;
                } else {
                    setError('Server Error. Plese contact <b><a href="mailto:support@kptd.ph">support@kptd.ph</a></b>');
                }
            } else {
                setError(errorMessage(json));
            }

        } catch (err) {
            setError(errorMessage(err));
        }

        setIsVerify(true);
    }

    async function updatePassword(e: any) {
        e.preventDefault();
        setShowPass(false);
        setError('');
        setLoading(true);

        try {

            const params = new URLSearchParams(window.location.search);
            const token = params.get('reset');

            const res = await api.patch(`/user/password/reset`, { password, token });

            const json = await res.json();
            if (res.ok) {
                toast.success(json.message);
            } else {
                setError(errorMessage(json));
            }
        } catch (err) {
            setError(errorMessage(err));
        }
        setLoading(false);
    }

    if (isReset) {
        return (
            <>
                <div className='w-screen h-screen flex flex-col justify-center'>

                    <div className='mx-auto w-[480px]'>
                        <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Create New Password</h2>

                        <div className="bg-white py-12 shadow rounded-md px-12 mt-10">

                            <form onSubmit={updatePassword}>

                                <div className="mb-5">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">New Password</label>
                                    <div className="mt-1 relative">
                                        <input
                                            id="hs-toggle-password"
                                            name="password"
                                            type={showPass ? 'text' : "password"}
                                            required
                                            value={password}
                                            disabled={loading}
                                            placeholder="Enter new password"
                                            autoComplete="current-password"
                                            className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-sm"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <span className="absolute inset-y-0 end-0 flex items-center px-3 cursor-pointer text-gray-500" onClick={loading ? undefined : () => setShowPass(!showPass)}>{showPass ? <FaRegEye className="text-blue-500" /> : <FaRegEyeSlash />}</span>
                                    </div>
                                </div>

                                {error && <div className="text-center text-red-500 italic text-sm mb-5"><div dangerouslySetInnerHTML={{ __html: error }} /></div>}

                                <div className="mt-10">
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="disabled:bg-gray-500 flex flex-row items-center w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                        {loading && <Spinner className="mr-2" />} Change
                                    </button>
                                </div>

                            </form>

                        </div>

                        <p className="mt-10 text-center text-sm text-gray-500">
                            <a href="/signin" className="font-semibold leading-6 text-blue-600 hover:text-black">Sign In</a>
                        </p>

                        <p className="mt-10 text-center text-sm text-gray-500">
                            <a href="/" className="font-semibold leading-6 text-black hover:text-blue-600">
                                Go Back To Home
                            </a>
                        </p>

                    </div>

                </div>

                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    transition={Bounce}
                />
            </>
        )
    }

    if (isVerify) {
        return (
            <div className='w-screen h-screen text-center flex flex-col bg-white justify-center'>

                {error && <>
                    <div><PiWarningCircleThin className='inline text-red-500' size={100} /></div>
                    <div className='mt-5 text-red-500 font-medium tracking-wide' dangerouslySetInnerHTML={{ __html: error }} />
                </>}

                {success && <>
                    <div><CiCircleCheck className='inline text-green-500' size={100} /></div>
                    <div className='mt-5 tracking-wide' dangerouslySetInnerHTML={{ __html: success }} />
                    <p className="mt-10 text-center text-sm text-gray-500">
                        <button onClick={() => goToPage('/signin')} className="cursor-pointer font-semibold leading-6 text-blue-600 hover:text-black">Sign In</button>
                    </p>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        <button onClick={() => goToPage('/')} className="cursor-pointer font-semibold leading-6 text-black hover:text-blue-600">Go Back To Home</button>
                    </p>
                </>}

            </div>
        )
    }

    return (<div className='w-screen h-screen text-center flex flex-col bg-white justify-center'><Loader className='w-[50px] h-[50px]' /></div>);


}
