import { ChangeEvent, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Spinner from "../../components/spinner";
import { RiSendPlane2Fill } from "react-icons/ri";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { AiFillCloseSquare } from "react-icons/ai";
import { MdBrowserUpdated } from 'react-icons/md';
import { arrayBufferToBase64, errorMessage } from '../../utils/utils';
import api from "../../utils/api";

export default function Contact() {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState<any>();
    const [fileLoading, setFileLoading] = useState(false);

    async function handleSubmit(e: any) {
        e.preventDefault();

        setError('');
        setSuccess('');
        setLoading(true);

        try {
            const res = await api.post(`/contact`, { email, name, message, file });

            const json = await res.json();
            if (res.ok) {
                setSuccess(json.message);
                setOpen(true);
            } else {
                setError(errorMessage(json));
            }
        } catch (err: any) {
            setError(errorMessage(err));
        }

        setLoading(false);
    }

    async function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        if (e.target.files) {
            const result = e.target.files[0];

            if (result) {

                if (result.size > 15000000) {
                    setError('File is too large');
                    return;
                }

                setFileLoading(true);

                new Promise(async (resolve, reject) => {
                    const arrayBuffer = await result.arrayBuffer();

                    const base64String = arrayBufferToBase64(arrayBuffer);
    
                    setFile({
                        name: result.name,
                        size: result.size,
                        type: result.type,
                        data: base64String,
                    });

                    setFileLoading(false);

                    resolve(null);
    
                });

                
            }

        }
    }

    return (
        <>
            <div className="flex flex-col h-screen">
                <Header className='flex' />
                <div className="flex-1 my-10">

                    <div className='mx-auto px-5 w-full sm:max-w-[1200px]'>

                        <div className='text-center mx-auto w-full sm:w-[500px] mb-10'>
                            <h1 className='md:mt-10 mb-2 text-[30px] leading-tight'>Contact Us</h1>
                            <p>For any inquiries, please email us at <a href="mailto://support@kptd.ph" className="text-blue-500 font-medium">support@kptd.ph</a> or fill out the form below, and we’ll get back to you soon!</p>
                        </div>

                        <div className="w-full sm:w-[500px] mt-10 mx-auto">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-5">
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                                    <div className="mt-1">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            value={email}
                                            required={true}
                                            disabled={loading}
                                            placeholder="name@domain.com"
                                            autoComplete="email"
                                            className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-sm"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name</label>
                                    <div className="mt-1">
                                        <input
                                            id="name"
                                            name="name"
                                            type="text"
                                            required={true}
                                            value={name}
                                            disabled={loading}
                                            placeholder="Juan Dela Cruz"
                                            className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 placeholder:text-sm"
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="message" className="block text-sm font-medium leading-6 text-gray-900">Your Message</label>
                                    <div className="mt-1">
                                        <textarea
                                            id="message"
                                            name="message"
                                            value={message}
                                            required={true}
                                            disabled={loading}
                                            onChange={(e) => setMessage(e.target.value)}
                                            rows={10}
                                            className="block p-2.5 w-full text-gray-900 rounded-lg border border-gray-300 placeholder:text-sm placeholder:text-gray-400"
                                            placeholder="Write your message here..."></textarea>
                                    </div>
                                </div>

                                <div className="mb-5 flex flex-row items-center">
                                    <label htmlFor="browse" className={`w-full ${fileLoading || loading ? '' : 'cursor-pointer'}`}>
                                        <input disabled={fileLoading || loading} className="hidden" id="browse" accept='.jpg,.jpeg,.png,.bmp,.doc,.docx,.xls,.xlsx,.csv,.mp4' type="file" onChange={handleOnChange} />
                                        <div className={`h-[36px] w-full border-[1.8px] rounded-[5px] px-3 py-1 flex flex-row items-center border-gray-300 ${loading ? 'bg-transparent' : 'bg-white'}`}>
                                            {file ? <div className='text-sm'>{file?.name}</div> : <div className='italic text-xs text-gray-400'>Attach a file (max 15 Megabytes)</div>}
                                            <div className='grow'></div>
                                            { fileLoading ? <Spinner /> : <MdBrowserUpdated className={loading ? 'text-gray-400' : 'text-blue-500'} />}
                                        </div>
                                    </label>
                                    {file && <AiFillCloseSquare className={`ml-1 ${loading ? 'text-gray-400' : 'cursor-pointer text-red-500'}`} size={25} onClick={loading ? undefined : () => {
                                        (document.getElementById('browse') as any).value = null;
                                        setFile(null);
                                    }} />}
                                </div>

                                {error && <div className="text-center text-red-500 italic text-sm mt-5"><div dangerouslySetInnerHTML={{ __html: error }} /></div>}

                                <div className="mt-10">

                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="disabled:bg-gray-400 flex flex-row items-center gap-2 w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                    >
                                        {loading ? <Spinner /> : <RiSendPlane2Fill />}
                                        Send Message
                                    </button>

                                </div>
                            </form>
                        </div>


                    </div>

                </div>

                <Footer />
            </div>

            <Dialog open={open} onClose={() => { }} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-200 sm:mx-0 sm:h-10 sm:w-10">
                                        <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-700" />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            Inquiry Sent
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <div className="text-sm text-gray-700"><div dangerouslySetInnerHTML={{ __html: success }} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                {/* <button
                                    type="button"
                                    onClick={() => setOpen(false)}
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                >
                                    Deactivate
                                </button> */}
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => setOpen(false)}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    OKAY
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>

            </Dialog>
        </>
    )
}