
export default {

    get: async (path: string): Promise<Response> => {
        try {

            const token =  window.localStorage.token;

            let res = await request(path, 'GET', token);

            if (res.status === 401 && token) {
                const json = await res.clone().json();

                if (json.expired === true) {
                    const newToken = await getRefreshToken();
                    res = await request(path, 'GET', newToken);
                }
            }

            return res;

        } catch (error) {
            console.trace('Error:', error);
            return new Response(JSON.stringify({ error: 'Server error'}), { status: 503, statusText: 'Server error' });
        }
    },

    post: async (path: string, body?: object): Promise<Response> => {

        try {

            const token =  window.localStorage.token;

            let res = await request(path, 'POST', token, body);

            if (res.status === 401 && token) {
                const json = await res.clone().json();
                if (json.expired === true) {
                    const newToken = await getRefreshToken();
                    res = await request(path, 'POST', newToken, body);
                }
            }

            return res;

        } catch (error) {
            console.trace('Error:', error);
            return new Response(JSON.stringify({ error: 'Server error'}), { status: 503, statusText: 'Server error' });
        }
    },

    patch: async (path: string, body?: object): Promise<Response> => {
        try {

            const token =  window.localStorage.token;

            let res = await request(path, 'PATCH', token, body);

            if (res.status === 401 && token) {
                const json = await res.clone().json();
                if (json.expired === true) {
                    const newToken = await getRefreshToken();
                    res = await request(path, 'PATCH', newToken, body);
                }
            }


            return res;

        } catch (error) {
            console.trace('Error:', error);
            return new Response(JSON.stringify({ error: 'Server error'}), { status: 503, statusText: 'Server error' });
        }
    },

    delete: async (path: string, body?: object): Promise<Response> => {

        try {

            const token =  window.localStorage.token;

            let res = await request(path, 'DELETE', token, body);

            if (res.status === 401 && token) {
                const json = await res.clone().json();
                if (json.expired === true) {
                    const newToken = await getRefreshToken();
                    res = await request(path, 'DELETE', newToken, body);
                }
            }


            return res;

        } catch (error) {
            console.trace('Error:', error);
            return new Response(JSON.stringify({ error: 'Server error'}), { status: 503, statusText: 'Server error' });
        }
    },

}


async function getRefreshToken(): Promise<string | undefined> {

    const token = window.localStorage.token;
    const refresh = window.localStorage.refresh;

    if (token && refresh) {
        const res = await fetch(`${process.env.REACT_APP_ENDPOINT}/auth/token/refresh`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify({ token, refresh }),
        });

        if (res.ok) {
            const json = await res.json();
            const newToken = json.token;
            window.localStorage.token = newToken;
            return newToken;
        }
    }

    return undefined;
}


function request(path: string, method: string, token?: string, body?: object): Promise<Response> {

    let url = '';

    if (path.startsWith('http://') || path.startsWith('https://')) {
        url = path;
    } else {
        path = path.startsWith('/') ? path : '/' + path;
        url = `${process.env.REACT_APP_ENDPOINT}${path}`;
    }

    const options: any = { method: method, headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', } };

    if (token) {
        options.headers['x-token'] = window.localStorage.token;
    }

    if (method.toUpperCase() !== 'GET' && body) {
        options['body'] = JSON.stringify(body);
    }

    return fetch(url, options);
}
