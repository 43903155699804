import Header from '../../components/header'
import Footer from '../../components/footer'

export default function PrivacyPolicy() {
    return (
        <div className="flex flex-col h-screen">

            <Header className='flex' />

            <div className='flex-1 my-8 md:my-10'>
                <div className='mx-auto w-full md:max-w-[1200px] px-5'>

                    <div className='text-center'>
                        <h1 className='md:mt-10 text-[30px] leading-tight'>Privacy Policy</h1>
                    </div>

                    <div className='bg-white py-5 px-10 rounded-[5px] mt-5 md:my-10 text-sm'>

                        <div className='mb-5 mt-5'><b>KPTD Network and Data Solution</b> (“Company,” “we,” “us,”, “our” or “<b>KPTD</b>”) is committed to protecting your privacy. This Privacy Policy (“Policy”) explains how we collect, use, disclose, and safeguard your information when you visit our website located at <a href="https://www.kptd.ph" className='text-blue-600'>https://www.kptd.ph</a> (the “Site”) and use our related products, services, or applications (collectively, the “Service”). By using our Site or Service, you agree to the terms of this Policy.</div>

                        <ul className='list-decimal px-10'>

                            <li className='mb-8'>
                                <div className='font-medium text-lg mb-2'>Information We Collect</div>

                                <div>
                                    <ul className='list-[upper-roman] px-8'>
                                        <li className='mb-3'>
                                            <div className='font-medium text-base'>Personal Information</div>
                                            <div className='mb-2'>We may collect personal information that you provide to us directly, such as:</div>
                                            <ul className='list-disc px-8'>
                                                <li>Contact Information: Name, email address, phone number, and postal address.</li>
                                                <li>Account Information: Username, password, and other registration details.</li>
                                                <li>Payment Information: Credit card details or other payment methods, when applicable.</li>
                                            </ul>
                                        </li>

                                        <li className='mb-3'>
                                            <div className='font-medium text-base'>Usage Data</div>
                                            <div className='mb-2'>We may collect information about your interactions with our Site and Service, including:</div>
                                            <ul className='list-disc px-8'>
                                                <li>Log Data: IP address, browser type, operating system, pages viewed, and the dates and times of your visits.</li>
                                                <li>Usage Information: Information about how you use our Site and Service, including access times and referring website addresses.</li>
                                                <li>Cookies and Tracking Technologies: We use cookies, web beacons, and similar technologies to track usage and preferences.</li>
                                            </ul>
                                        </li>

                                    </ul>
                                </div>

                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-lg'>How We Use Your Information</div>
                                <div className='mb-2'>We use the information we collect for various purposes, including:</div>
                                <ul className='list-disc px-8'>
                                    <li>Providing and Improving Service: To operate, maintain, and improve our Site and Service.</li>
                                    <li>Personalization: To personalize your experience and provide content relevant to your interests.</li>
                                    <li>Communication: To respond to your inquiries, send you updates, and provide customer support.</li>
                                    <li>Marketing: To send promotional materials and offers, subject to your consent where required.</li>
                                    <li>Billing: To process transactions and manage payments.</li>
                                </ul>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-lg'>How We Share Your Information</div>
                                <div className='mb-2'>We may share your information in the following situations:</div>
                                <ul className='list-disc px-8'>
                                    <li>With Service Providers: We may share information with third-party vendors who perform services on our behalf, such as payment processing, analytics, or customer support.</li>
                                    <li>For Legal Reasons: We may disclose information if required by law or in response to legal processes, or to protect the rights, property, or safety of <b>KPTD</b>, our users, or others.</li>
                                    <li>Business Transfers: If we undergo a merger, acquisition, or any form of sale of some or all of our assets, your information may be transferred as part of that transaction.</li>
                                </ul>
                            </li>


                            <li className='mb-8'>
                                <div className='font-medium text-lg'>Security of Your Information</div>
                                <div>We use administrative, technical, and physical security measures to help protect your personal information. However, no method of transmission over the internet or method of electronic storage is completely secure. While we strive to protect your information, we cannot guarantee its absolute security.</div>
                            </li>


                            <li className='mb-8'>
                                <div className='font-medium text-lg mb-2'>Your Choices</div>

                                <div>
                                    <ul className='list-disc px-8'>
                                        <li className='mb-3'>
                                            <div className='font-medium text-base'>Access and Correction</div>
                                            <div className='mb-2'>You may access, update, or correct your personal information by logging into your account or contacting us directly.</div>
                                        </li>

                                        <li className='mb-3'>
                                            <div className='font-medium text-base'>Opt-Out</div>
                                            <div className='mb-2'>You may opt out of receiving promotional communications from us by following the unsubscribe instructions provided in those communications or contacting us directly.</div>
                                        </li>

                                        <li className='mb-3'>
                                            <div className='font-medium text-base'>Cookies</div>
                                            <div className='mb-2'>You can manage cookies through your browser settings. Please note that disabling cookies may affect your ability to use certain features of our Site and Service.</div>
                                        </li>

                                    </ul>
                                </div>

                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-lg'>Children’s Privacy</div>
                                <div>Our Site and Service are not intended for use by individuals under the age of 12. We do not knowingly collect personal information from children under this age. If we become aware that we have collected personal information from a child without parental consent, we will take steps to delete such information.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-lg'>International Transfers</div>
                                <div>If you are accessing our Site or Service from outside Philippines, please be aware that your information may be transferred to, stored, and processed in Philippines. By using our Site or Service, you consent to the transfer of your information to Philippines and other countries where we operate.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-lg'>Changes to This Privacy Policy</div>
                                <div>We may update this Policy from time to time to reflect changes in our practices or legal requirements. We will post any changes on this page and update the effective date at the top. Your continued use of the Site or Service following the posting of changes constitutes your acceptance of such changes.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-lg'>Contact Us</div>
                                <div className='mb-3'>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</div>
                                <div>KPTD Network and Data Solution</div>
                                <div>Santiago City, Isabela, Philippines, 3311</div>
                                <div>support@kptd.ph</div>
                                <div>{ }</div>

                                <div className='mt-8'>Thank you for trusting <b>KPTD Network and Data Solution</b> with your personal information. We are committed to protecting your privacy and ensuring a secure experience with our Site and Service.</div>
                            </li>

          

                        </ul>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}
