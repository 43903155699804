import { BiSolidDashboard } from "react-icons/bi";
import { PiLinkFill } from "react-icons/pi";
import logo from "../../../logo.png";
import { IoIosListBox } from "react-icons/io";

export default function UserNav({ nav, className }: any) {
  return (
    <nav className={`flex w-[250px] bg-blue-500 shadow-md h-screen flex flex-col ${className && className}`}>

      <div className='h-[100px] w-full'>

      <div className="flex flex-row items-center justify-center pt-8 gap-2">
            <a href="/"><img className="w-[40px] h-[40px] rounded-full" src={logo} alt="" /></a>
            <a href="/"><span className="text-white font-bold text-xl tracking-widest">KPTD</span></a>
          </div>
      </div>

      <a href='/user/dashboard' className={`mb-[1px] text-white h-[50px] items-center flex px-3 font-medium hover:bg-blue-900 ${nav === 'dashboard' && 'bg-blue-700'}`}>
        <BiSolidDashboard className="mr-2" /> Dashboard
      </a>

      <a href='/user/remote' className={`mb-[1px] text-white h-[50px] items-center flex px-3 font-medium hover:bg-blue-900 ${nav === 'remote' && 'bg-blue-700'}`}>
        <PiLinkFill className="mr-2" /> Remote Access
      </a>

      <a href='/user/transaction' className={`mb-[1px] text-white h-[50px] items-center flex px-3 font-medium hover:bg-blue-900 ${nav === 'transaction' && 'bg-blue-700'}`}>
        <IoIosListBox className="mr-2" /> Transactions
      </a>
    </nav>
  )
}
