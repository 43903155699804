import { useEffect } from "react";
import { BsExclamationDiamond } from "react-icons/bs";

export default function PaymentFailed() {

  useEffect(()=>{
    setTimeout(()=> {
      window.close();
    }, 5000);
  });
  
  return (<div className='w-screen h-screen text-center flex flex-col bg-white justify-center'>
    <div className='text-center w-full'>

      <div className='bg-red-100 h-[130px] w-[130px] rounded-full mx-auto text-center items-center justify-center flex flex-row'>
        <BsExclamationDiamond  size={80} className='text-red-500' />
      </div>
      <div className='text-lg mt-5'>Payment Failed!</div>
      <div className='mt-5 mb-10 mx-auto px-5 text-sm w-[400px]'>
        We’re sorry, but your payment could not be processed at this time. Please check your payment details and try again.
        <br />
        <br />
        If you continue to experience issues, <a href="/contact" className="text-blue-500 font-medium">click here</a> to contact our support team or email us at <span className="font-medium">support@kptd.ph</span>
      </div>
      <a href='/' className='font-medium text-blue-500'>KPTD.ph</a>
    </div>
  </div>);
}
