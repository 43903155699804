import Header from '../../components/header'
import Footer from '../../components/footer'

export default function TermsAndConditions() {
    return (
        <div className="flex flex-col h-screen">

            <Header className='flex' />

            <div className='flex-1 my-8 md:my-10'>
                <div className='mx-auto w-full md:max-w-[1200px] px-5'>

                    <div className='text-center'>
                        <h1 className='md:mt-10 text-[30px] leading-tight'>Terms and Conditions</h1>
                    </div>

                    <div className='bg-white py-5 px-10 rounded-[5px] mt-5 md:my-10 text-sm'>

                        <div className='mb-5 mt-5'>Welcome to <b>KPTD Network and Data Solution</b> (“Company,” “we,” “us,”, “our” or “<b>KPTD</b>”). These Terms and Conditions (“Terms”) govern your access to and use of our website located at <a href='https://www.kptd.ph' className='text-blue-600'>https://www.kptd.ph</a> (the “Site”) and any related services, applications, or products provided by us (collectively, the “Service”). By accessing or using our Site or Service, you agree to comply with these Terms and our Privacy Policy.</div>

                        <ul className='list-decimal px-10'>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Acceptance of Terms</div>
                                <div>By using our Site or Service, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, please do not use our Site or Service.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Changes to Terms</div>
                                <div>We may modify these Terms at any time. Changes will be posted on this page, and your continued use of the Site or Service following such changes will constitute your acceptance of the modified Terms. We encourage you to review these Terms periodically.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Eligibility</div>
                                <div>To use our Site or Service, you must be at least 12 years old and capable of forming a legally binding contract. If you are using the Site or Service on behalf of an organization, you represent that you have the authority to bind that organization.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Account Registration</div>
                                <div>Certain features of our Site or Service may require you to create an account. You agree to provide accurate, current, and complete information during registration and to keep your account information updated. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Use of Service</div>
                                <div>
                                    <div className='mb-2'>You agree to use our Site and Service in a lawful manner and in compliance with these Terms. Prohibited activities include:</div>
                                    <ul className='list-disc px-8'>
                                        <li className='mb-1'>Violating any applicable laws or regulations</li>
                                        <li className='mb-1'>Harassing, abusing, or threatening others</li>
                                        <li className='mb-1'>Impersonating any person or entity</li>
                                        <li className='mb-1'>Transmitting harmful, disruptive, or deceptive content</li>
                                        <li className='mb-1'>Attempting to gain unauthorized access to our systems or networks</li>
                                    </ul>
                                </div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Intellectual Property</div>
                                <div>All content on our Site and Service, including text, graphics, logos, images, and software, is the property of <b>KPTD</b> or its licensors and is protected by intellectual property laws. You may not use, reproduce, or distribute any content from our Site or Service without our express written permission.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Third-Party Links</div>
                                <div>Our Site and Service may include links to third-party websites or services. We do not endorse or take responsibility for the content or practices of these third parties. Your use of third-party sites is at your own risk.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Disclaimers and Limitation of Liability</div>
                                <div>Our Site and Service are provided “as is” and “as available” without warranties of any kind. We do not guarantee that the Site or Service will be uninterrupted, secure, or error-free. To the fullest extent permitted by law, <b>KPTD</b> will not be liable for any indirect, incidental, consequential, or punitive damages arising from your use of the Site or Service.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Indemnification</div>
                                <div>You agree to indemnify and hold harmless <b>KPTD</b>, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses arising out of your use of the Site or Service or any violation of these Terms.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Termination</div>
                                <div>We reserve the right to suspend or terminate your access to the Site or Service at our discretion, with or without cause, and with or without notice. Upon termination, you must cease all use of the Site and Service.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Privacy</div>
                                <div>Your use of our Site and Service is governed by our Privacy Policy. Please review our Privacy Policy to understand how we collect, use, and protect your personal information.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Feedback</div>
                                <div>Any feedback, suggestions, or ideas you provide regarding our Site or Service are given on a non-confidential basis. We may use and incorporate such feedback without any obligation or compensation to you.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Force Majeure</div>
                                <div>We are not liable for any delay or failure in performing our obligations under these Terms due to circumstances beyond our reasonable control, including but not limited to natural disasters, war, or government actions.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Assignment</div>
                                <div>We may assign or transfer our rights and obligations under these Terms without restriction. You may not assign or transfer your rights or obligations without our prior written consent.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Severability</div>
                                <div>If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will continue in full force and effect. The invalid or unenforceable provision will be modified to the extent necessary to make it valid and enforceable.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Entire Agreement</div>
                                <div>These Terms, together with our Privacy Policy and any other agreements incorporated by reference, constitute the entire agreement between you and <b>KPTD</b> regarding your use of the Site and Service and supersede all prior agreements and understandings.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>No Waiver</div>
                                <div>No waiver of any term or condition of these Terms will be deemed a further or continuing waiver of such term or condition or any other term or condition. Our failure to enforce any right or provision will not constitute a waiver of such right or provision.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Governing Law</div>
                                <div>These Terms are governed by and construed in accordance with the laws of Philippines, without regard to its conflict of law principles. Any disputes arising under these Terms will be subject to the exclusive jurisdiction of the courts located in Philippines.</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Contact Us</div>
                                <div className='mb-3'>For any questions or concerns regarding these Terms or our Site or Service, please contact us at:</div>
                                <div>KPTD Network and Data Solution</div>
                                <div>Santiago City, Isabela, Philippines, 3311</div>
                                <div>support@kptd.ph</div>
                                <div>{}</div>
                            </li>

                            <li className='mb-8'>
                                <div className='font-medium text-base mb-2'>Accessibility</div>
                                <div className='mb-5'>We are committed to making our Site and Service accessible to all users. If you encounter any issues or need accommodations, please contact us so we can address your needs.</div>
                                <div>Thank you for using <b>KPTD Network and Data Solution</b>. We appreciate your use of our Site and Service!</div>
                            </li>

                        </ul>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}
