import { useEffect, useState } from "react";
import Loader from "../../../../components/loader";
import UserNav from "../../components/userNav";
import UserHeader from "../../components/userHeader";
import api from "../../../../utils/api";
import { errorMessage } from "../../../../utils/utils";
import { useParams } from "react-router-dom";
import { IoMdArrowBack, IoMdRefreshCircle } from "react-icons/io";
import Spinner from "../../../../components/spinner";
import { Bounce, toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { FaEdit, FaSave } from "react-icons/fa";
import { IoSaveSharp } from "react-icons/io5";
import { AiFillCloseSquare } from "react-icons/ai";


const ports = [
    { value: '8291', label: 'Winbox (8291)' },
    { value: '80', label: 'HTTP (80)' },
    { value: '443', label: 'HTTPS (443)' },
    { value: '22', label: 'SSH (22)' },
    { value: 'custom', label: 'Custom' },
];

export default function RemoteItem() {

    useEffect(() => {

        if (!window.localStorage.token) {
            window.location.replace('/signin');
            return;
        }

        setPageLoading(false);

        getData();

    }, []);

    const [pageLoading, setPageLoading] = useState(true);
    const [nav, setNav] = useState(false);

    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [data, setData] = useState<any>();

    const [serverLoading, setServerLoading] = useState(true);
    const [servers, setServers] = useState<any>([]);
    const [services, setServices] = useState<any>([]);

    const [label, setLabel] = useState('');
    const [service, setService] = useState('');
    const [server, setServer] = useState('');
    const [app_port, setAppPort] = useState('');
    const [custom, setCustom] = useState('');
    const [protocol, setProtocol] = useState('');

    const [update, setUpdate] = useState('');
    const [updating, setUpdating] = useState(false);

    async function getData() {

        setError('');
        setLoading(true);
        try {

            const res = await api.get(`/remote/${id}`);
            const json = await res.json();
            if (res.ok) {
                setData(json);
                setLabel(json.label);
                setServer(json.server);
                setService(json.service);
                setAppPort(json.app_port);
                setCustom(json.custom);
                setProtocol(json.protocol);
                getServers(json.server);
            } else {
                setServerLoading(false);
                setError(errorMessage(json));
            }
        } catch (error) {
            setServerLoading(false);
            setError(errorMessage(error));
        }


        setLoading(false);
    }

    async function getServers(name?: string) {
        try {
            const res = await api.get(`/remote/server?subs=paid`);
            const json = await res.json();
            if (res.ok) {
                setServers(json);
                if (json.length > 0) {
                    const find = json.find((item: any) => item.name === name);
                    if (find) {
                        setServices(find.services);
                    }
                }
            } else {
                toast.error(errorMessage(json));
            }
        } catch (err) {
            toast.error(errorMessage(err));
        }

        setServerLoading(false);
    }

    async function updateData() {
        if (!update) return;

        switch (update) {
            case 'label':
                if (data.label === label) return;
                break;
            case 'service':
                if (data.service === service) return;
                break;
            case 'app_port':
                if (app_port === 'custom') {
                    if (data.protocol === protocol && data.custom === custom) return;
                } else {
                    if (data.app_port === app_port) return;
                }
                break;
        }

        setUpdating(true);

        try {
            const res = await api.patch(`/remote/${id}`, { update, label, server, service, app_port, custom, protocol });
            const json = await res.json();
            if (res.ok) {
                toast.success(json.message);

                switch (update) {
                    case 'label':
                        data.label = label;
                        break;
                    case 'service':
                        data.service = service;
                        break;
                    case 'app_port':
                        data.app_port = app_port;
                        data.protocol = protocol;
                        data.custom = custom;
                        break;
                }

                startEdit('');

            } else {
                toast.error(errorMessage(json));
            }

        } catch (err) {
            toast.error(errorMessage(err));
        }

        setUpdating(false);
    }


    function getDate() {

        const date = data?.date_created ? moment(data.date_created).format('YYYY-MM-DD @ hh:mm:ss a') : undefined;
        return date;
    }

    function getExpiration() {

        const date = data?.date_expiration ? moment(data.date_expiration).format('YYYY-MM-DD @ hh:mm:ss a') : undefined;
        return date;
    }

    function getDaysLeft() {

        let daysLeft;

        try {
            const expDate = Date.parse(data.date_expiration?.toString());

            if (expDate) {
                const now = new Date();
                const diff = (expDate - now.getTime()) / (1000 * 60 * 60 * 24);
                daysLeft = diff.toFixed(0);
            }
        } catch (error) {

        }

        return daysLeft;
    }

    function getServerLabel() {
        let label = '';
        const find = servers.find((item: any) => item.name === data?.server);

        if (find) {
            label = find.label;
        }

        return label;
    }

    function getAppPort() {

        let label = '';

        if (data?.app_port === 'custom') {
            label = data?.protocol?.toUpperCase() + ':' + data?.custom;
        } else {
            const find = ports.find((item: any) => item.value === data?.app_port);
            if (find) {
                label = find.label;
            }
        }

        return label;
    }

    function startEdit(name: string) {
        if (updating) return;

        setLabel(data?.label);
        setServer(data?.server);
        setService(data?.service);
        setAppPort(data?.app_port);
        setCustom(data?.custom);
        setProtocol(data?.protocol);
        setUpdate(name);
    }


    if (pageLoading) {
        return (<div className='w-screen h-screen text-center flex flex-col bg-white justify-center'><Loader /></div>)
    }

    return (
        <>
            <div className='flex flex-row w-full'>
                <div className="hidden xl:block"><UserNav nav='remote' /></div>
                <main className='grow w-screen flex flex-col'>

                    <UserHeader title={`My Remote Access > ${label}`} setNav={() => { setNav(!nav) }} />

                    <section className="p-5">
                        {/* <IoMdArrowBack className="cursor-pointer h-[30px] w-[30px]" onClick={() => window.history.back()} /> */}

                        <div className={`w-full px-5 ${!loading && 'hidden'}`}>
                            <div className='mx-auto text-center'><Spinner className='w-8 h-8 inline text-blue-500 fill-gray-400' /></div>
                        </div>

                        <div className={`w-full bg-white rounded-xl p-5 flex flex-col ${(loading || !data || error) && 'hidden'}`}>

                            <div className="flex flex-row items-center">

                                <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Label</label>

                                <div className={`text-blue-500 ${update === 'label' && 'hidden'}`}>{data?.label}</div>

                                <div className={`${update !== 'label' && 'hidden'}`}>
                                    <input
                                        name="label"
                                        type="text"
                                        required={true}
                                        value={label}
                                        disabled={loading || updating}
                                        // placeholder="Just a label for your device"
                                        className="w-full md:w-[300px] text-blue-500 no-ring border-0 border-b-[1px] border-gray-400"
                                        onChange={(e) => setLabel(e.target.value)}
                                    />
                                </div>

                                <FaEdit className={`ml-3 text-blue-500 w-[11px] h-[11px] cursor-pointer ${update === 'label' && 'hidden'}`} onClick={() => startEdit('label')} />

                                <div className={`flex flex-row items-center ${(update !== 'label' || updating) && 'hidden'}`}>
                                    <AiFillCloseSquare className={`ml-3 text-gray-500 w-[18px] h-[18px] cursor-pointer`} onClick={() => startEdit('')} />
                                    <FaSave className={`ml-3 text-green-500 w-[16px] h-[16px] cursor-pointer`} onClick={updateData} />
                                </div>

                                <Spinner className={`ml-3 fill-blue-500 text-blue-100 ${(update !== 'label' || !updating) && 'hidden'}`} />

                            </div>

                            <div className="mt-1 flex flex-row items-center">
                                <label htmlFor="server" className="block text-sm leading-6 text-gray-900 w-[100px]">Server</label>
                                {serverLoading && <Spinner className='ml-2' />}
                                <div className={`${update === 'server' && 'hidden'}`}>{getServerLabel()}</div>
                                <div className={`relative ${update !== 'server' && 'hidden'}`}>
                                    {serverLoading && <Spinner className='absolute ml-2 mt-[5px]' />}
                                    <select
                                        name='server'
                                        value={server}
                                        required={true}
                                        disabled={loading || serverLoading || updating}
                                        className={`no-ring w-full md:w-[300px] border-0 border-b-[1px] border-gray-400`}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value) {
                                                const find = servers.find((item: any) => item.name === value);
                                                if (find) {
                                                    setServices(find.services);
                                                }
                                            } else {
                                                setServices([])

                                            }

                                            setServer(value);
                                        }}>
                                        {!serverLoading && <option value=''>Please select...</option>}
                                        {
                                            servers.map((item: any, index: number) => {
                                                return <option key={`${index}-server`} value={item.name} disabled={!item.enabled}>{item.label ?? item.name}{!item.enabled && ' (unavailable)'}</option>
                                            })
                                        }

                                    </select>
                                </div>

                                <div className={`flex flex-row items-center ${serverLoading && 'hidden'}`}>
                                    <FaEdit className={`ml-3 text-gray-500 w-[11px] h-[11px] ${update === 'server' && 'hidden'}`} onClick={undefined /*() => startEdit('server')*/} />
                                    <div className={`flex flex-row items-center ${(update !== 'server' || updating) && 'hidden'}`}>
                                        <AiFillCloseSquare className={`ml-3 text-gray-500 w-[18px] h-[18px]`} onClick={undefined /*() => startEdit('')*/} />
                                        <FaSave className={`ml-3 text-gray-500 w-[16px] h-[16px]`} onClick={undefined /*updateData*/} />
                                    </div>
                                    <Spinner className={`ml-3 fill-blue-500 text-blue-100 ${(update !== 'server' || !updating) && 'hidden'}`} />
                                </div>
                            </div>

                            <div className="mt-1 flex flex-row items-center">
                                <label htmlFor="service" className="block text-sm leading-6 text-gray-900 w-[100px]">Service</label>
                                <div className={`${update === 'service' && 'hidden'}`}>{data?.service?.toUpperCase()}</div>
                                <select
                                    name='service'
                                    value={service}
                                    style={{ width: "200px" }}
                                    required={true}
                                    disabled={loading || !server || updating}
                                    className={`no-ring border-0 border-b-[1px] border-gray-400 ${update !== 'service' && 'hidden'}`}
                                    onChange={(e) => setService(e.target.value)}>
                                    <option value=''>Please select...</option>
                                    {
                                        services.map((item: any, index: number) => {
                                            return <option key={`${index}-service`} value={item}>{item?.toUpperCase()}</option>
                                        })
                                    }
                                </select>
                                <FaEdit className={`ml-3 text-blue-500 w-[11px] h-[11px] cursor-pointer ${update === 'service' && 'hidden'}`} onClick={() => startEdit('service')} />
                                <div className={`flex flex-row items-center ${(update !== 'service' || updating) && 'hidden'}`}>
                                    <AiFillCloseSquare className={`ml-3 text-gray-500 w-[18px] h-[18px] cursor-pointer`} onClick={() => startEdit('')} />
                                    <FaSave className={`ml-3 text-green-500 w-[16px] h-[16px] cursor-pointer`} onClick={updateData} />
                                </div>
                                <Spinner className={`ml-3 fill-blue-500 text-blue-100 ${(update !== 'service' || !updating) && 'hidden'}`} />
                            </div>

                            <div className="mt-1 flex flex-row items-center">
                                <label htmlFor="application" className="block text-sm leading-6 text-gray-900 w-[100px]">Application</label>
                                <div className={`${update === 'app_port' && 'hidden'}`}>{getAppPort()}</div>
                                <select
                                    name='application'
                                    value={app_port}
                                    style={{ width: "200px" }}
                                    required={true}
                                    disabled={loading || !server || updating}
                                    className={`no-ring border-0 border-b-[1px] border-gray-400 ${update !== 'app_port' && 'hidden'}`}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value === 'custom') {
                                            if (!protocol) {
                                                setProtocol('tcp');
                                            }
                                        } else {
                                            setCustom('');
                                            setProtocol('');
                                        }
                                        setAppPort(value);

                                    }}>
                                    <option value=''>Please select...</option>
                                    {
                                        ports.map((item: any, index: number) => {
                                            return <option key={`${index}-port`} value={item.value}>{item.label}</option>
                                        })
                                    }
                                </select>
                                <FaEdit className={`ml-3 text-blue-500 w-[11px] h-[11px] cursor-pointer ${update === 'app_port' && 'hidden'}`} onClick={() => startEdit('app_port')} />
                                <div className={`flex flex-row items-center ${(update !== 'app_port' || updating) && 'hidden'}`}>
                                    <AiFillCloseSquare className={`ml-3 text-gray-500 w-[18px] h-[18px] cursor-pointer`} onClick={() => startEdit('')} />
                                    <FaSave className={`ml-3 text-green-500 w-[16px] h-[16px] cursor-pointer`} onClick={updateData} />
                                </div>
                                <Spinner className={`ml-3 fill-blue-500 text-blue-100 ${(update !== 'app_port' || !updating) && 'hidden'}`} />

                            </div>

                            <div className={`mt-1 mb-2 flex flex-col gap-3 ${(app_port !== 'custom' || update !== 'app_port') && 'hidden'}`}>
                                <div className="flex flex-row items-center">
                                    <label htmlFor="custom" className="block text-sm leading-6 text-gray-900 w-[100px]">Port</label>
                                    <input
                                        name="custom"
                                        type="text"
                                        required={app_port === 'custom' ? true : false}
                                        value={custom}
                                        disabled={loading || !server || updating}
                                        style={{ width: "200px" }}
                                        maxLength={5}
                                        placeholder="0 to 65535"
                                        className="no-ring border-0 border-b-[1px] border-gray-400 placeholder:text-sm"
                                        onChange={(e) => {
                                            const re = /^[0-9\b]+$/;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                setCustom(e.target.value);
                                            }
                                        }}
                                    />
                                </div>

                                <div className="flex flex-row items-center">
                                    <label htmlFor="protocol" className="block text-sm leading-6 text-gray-900 w-[100px]">Protocol</label>
                                    <select
                                        name='protocol'
                                        required={app_port === 'custom' ? true : false}
                                        value={protocol}
                                        disabled={loading || !server || updating}
                                        style={{ width: "200px" }}
                                        className={`no-ring border-0 border-b-[1px] border-gray-400`}
                                        onChange={(e) => setProtocol(e.target.value)}>
                                        <option value=''>Please select...</option>
                                        <option value='tcp'>TCP</option>
                                        <option value='udp'>UDP</option>
                                    </select>
                                </div>
                            </div>

                            <div>
                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Created On:</label>
                                    <div>{getDate()}</div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Expired On:</label>
                                    <div>{getExpiration()}</div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Day(s) Left:</label>
                                    <div>{getDaysLeft()}</div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Plan:</label>
                                    <div>{data?.plan?.toUpperCase()}</div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Speed:</label>
                                    <div>{data?.bandwidth}</div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Status:</label>
                                    <span className={`px-2 py-[1.5px] rounded-md text-white font-bold text-xs ${data?.status === 'online' ? 'bg-green-500' : 'bg-gray-500'}`}>{data?.status}</span>
                                    <span className={`ml-1 px-2 py-[1.5px] rounded-md text-white font-bold text-xs bg-gray-500 ${!data?.expired && 'hidden'}`}>expired</span>
                                </div>

                                <div className={`${data?.status !== 'online' && 'hidden'}`}>
                                    <div className="mt-1 flex flex-row items-center">
                                        <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Up Time:</label>
                                        <div>{data?.uptime}</div>
                                    </div>

                                    <div className="mt-1 flex flex-row items-center">
                                        <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Remote IP:</label>
                                        <div>{data?.remote_ip}</div>
                                    </div>

                                </div>

                                {/* <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Connect To:</label>
                                    <div className="grow text-blue-500">{servers.map((item: any) => item.name === data?.server)?.domain}</div>
                                </div> */}

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Connect To:</label>
                                    <div className="text-blue-500">{data?.from_address}</div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">User:</label>
                                    <div className="text-blue-500">{data?.user}</div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Password:</label>
                                    <div className="text-blue-500">{data?.password}</div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Service Port:</label>
                                    <div className="text-blue-500">{data?.port}</div>

                                    <div className='group relative flex justify-center'>
                                        <IoMdRefreshCircle className={`ml-2 text-gray-500 w-[18px] h-[18px]`} onClick={() => { }} />
                                        <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Get a new service port (Unavailable)</span>
                                    </div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Remote Url:</label>
                                    <div className="text-blue-500">{data?.from_address}:{data?.port}</div>
                                </div>

                                <div className="mt-5 text-xs">Having troubles connecting? <a href={`/tutorials/how-to-connect-${data?.service}-client`} rel="noreferrer" target='_blank' className='text-blue-500 font-medium'>click here</a> for tutorials on how to connect.</div>

                            </div>

                            <div className="mt-5 flex flex-row gap-2">
                                <button onClick={() => window.history.back()} className={`border border-gray-400 rounded-md bg-white px-5 py-1 shadow-md`}>Back</button>
                                <div className="grow"></div>
                                {/* <button onClick={() => { }} className={`rounded-md text-white bg-blue-500 px-5 py-1 shadow-md ${update && 'hidden'}`}>Edit</button>
                                <div className={`flex flex-row gap-2 ${!update && 'hidden'}`}>
                                    <button onClick={() => startEdit('')} className="border border-gray-400 rounded-md bg-white px-5 py-1 shadow-md">Cancel</button>
                                    <button onClick={() => { }} className="rounded-md text-white bg-green-500 px-5 py-1 shadow-md">Save</button>
                                </div> */}

                            </div>

                        </div>

                        <div className={`w-full text-center px-5 ${(loading || !error) && 'hidden'}`}>
                            <div className='text-red-500 italic text-sm'><div dangerouslySetInnerHTML={{ __html: error }} /></div>
                        </div>

                    </section>
                </main>
            </div>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />

            <div className='xl:hidden'>
                {nav && <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0" onClick={() => setNav(false)}></div>}
                <div className={nav ? "fixed top-0 left-0 w-[250px] h-screen bg-white z-10 duration-300" : "fixed top-0 left-[-100%] w-[250px] h-screen bg-white z-10 duration-300"}>
                    <UserNav nav='remote' />
                </div>
            </div>
        </>
    )
}
