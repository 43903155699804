import { useEffect, useState } from "react";
import UserNav from "../components/userNav";
import UserHeader from "../components/userHeader";
import { Bounce, ToastContainer } from "react-toastify";
import Loader from "../../../components/loader";
import api from "../../../utils/api";
import { errorMessage } from "../../../utils/utils";
import Spinner from "../../../components/spinner";
import moment from "moment";
import { IoAddCircle, IoArrowUndoCircle, IoCopy } from "react-icons/io5";
import maya from '../../../assets/paymaya-logo.svg';
import gcash from '../../../assets/gcash-logo.svg';
import shopee from '../../../assets/shopeepay-logo-2.svg';
import grab from '../../../assets/grabpay-logo.svg';
import { FaExternalLinkSquareAlt, FaRegQuestionCircle } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import { MdAccessTimeFilled, MdPending } from "react-icons/md";
import { SiVoidlinux } from "react-icons/si";
import { FaCircleCheck, FaCircleExclamation } from "react-icons/fa6";

export default function UserTransaction() {

    const [pageLoading, setPageLoading] = useState(true);
    const [itemsLoading, setItemsLoading] = useState(true);
    const [error, setError] = useState('');
    const [itemsError, setItemsError] = useState('');
    const [items, setItems] = useState<any[]>([]);

    const [nav, setNav] = useState(false);


    useEffect(() => {
        const token = window.localStorage.token;

        if (!token) {
            window.location.replace('/signin');
            return;
        }

        setPageLoading(false);
        getTransactions();
    }, []);


    async function getTransactions() {
        setItems([]);
        setItemsLoading(true);
        try {
            const res = await api.get('/remote/transaction');
            const json = await res.json();
            if (res.ok) {
                setItems(json);
            } else {
                setError(errorMessage(json));
            }
        } catch (err) {
            setError(errorMessage(err));
        }
        setItemsLoading(false);
    }

    if (pageLoading) {
        return (<div className='w-screen h-screen text-center flex flex-col bg-white justify-center'><Loader /></div>)
    }

    return (
        <>
            <div className='flex flex-row'>

                <div className="hidden xl:block"><UserNav nav='transaction' className='fixed block left-[0px]' /></div>

                <main className='xl:ml-[250px] grow w-screen flex flex-col'>
                    <UserHeader title='Transaction List' setNav={() => { setNav(!nav) }} />

                    <section className="grow w-full">
                        <div className="hidden lg:block"><Large items={items} loading={itemsLoading} error={itemsError} /></div>
                        <div className="lg:hidden w-full"><Small items={items} loading={itemsLoading} error={itemsError} /></div>
                    </section>

                </main>
            </div>


            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />

            <div className='xl:hidden'>
                {nav && <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0" onClick={() => setNav(false)}></div>}
                <div className={nav ? "fixed top-0 left-0 w-[250px] h-screen bg-white z-10 duration-300" : "fixed top-0 left-[-100%] w-[250px] h-screen bg-white z-10 duration-300"}>
                    <UserNav nav='transaction' />
                </div>
            </div>
        </>
    )
}

function Small({ items, loading, error, }: any) {
    return (
        <>
            <div className={`mt-10 mx-auto text-center ${!loading && 'hidden'}`}><Spinner className='w-8 h-8 inline text-blue-500 fill-gray-400' /></div>
            <div className={`mt-10 mx-auto text-center ${(loading || !error) && 'hidden'}`}><div className='text-red-500 italic text-sm'><div dangerouslySetInnerHTML={{ __html: error }} /></div></div>

            <ul className="w-full p-5 flex flex-col gap-3">
                {
                    items.map((item: any, index: number) => {
                        const date = item.date ? moment(item.date).format('YYYY-MM-DD @ hh:mm:ss a') : '';

                        let payment: any;

                        switch (item.payment_method) {
                            case 'maya': payment = maya;
                                break;
                            case 'gcash': payment = gcash;
                                break;
                            case 'shopeepay': payment = shopee;
                                break;
                            case 'grabpay': payment = grab;
                                break;
                        }

                        let status = 'bg-gray-500';

                        switch (item.status) {
                            case 'CREATED':
                                status = 'bg-gray-500';
                                break;
                            case 'WAITING':
                                status = 'bg-gray-500';
                                break;
                            case 'ERROR':
                                status = 'bg-red-500';
                                break;
                            case 'SUCCEEDED':
                                status = 'bg-green-500';
                                break;
                            case 'PENDING':
                                status = 'bg-orange-500';
                                break;
                            case 'FAILED':
                                status = 'bg-red-500';
                                break;
                            case 'VOIDED':
                                status = 'bg-gray-500';
                                break;
                            case 'REFUNDED':
                                status = 'bg-blue-500';
                                break;
                        }

                        return <li key={`${index}-item`} className="bg-white px-5 py-3 rounded-md flex flex-col shadow-md">

                            <div className="flex flex-row gap-2 items-center">
                                <div className='group relative flex justify-center'>
                                    <IoCopy size={15} className='text-blue-500 cursor-pointer active:text-black' onClick={() => navigator.clipboard.writeText(`${item.id}`)} />
                                    <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Click to copy Reference Id:<br /><span className='font-medium text-sm'>{item.id}</span></span>
                                </div>
                                <div className="text-blue-500"><a href={`/user/transaction/${item.id}`} className="underline">{item.name}</a></div>
                                <div className='group relative flex justify-center'>
                                    <div className={`text-white px-[6px] py-[1px] rounded-md text-[10px] ${status}`}>{item.status?.toLowerCase()}</div>
                                    <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{(item.status === 'FAILED' || item.status === 'ERROR' ? item.failure ?? 'error' : item.status)?.toLowerCase()}</span>
                                </div>
                            </div>


                            <div className="flex flex-row items-center">
                                <div className="grow flex flex-col">
                                    <div className="text-sm">Transaction: <span className="font-medium">{item.action}</span></div>
                                    <div className="text-sm">Plan: <span className="font-medium">{item.plan}</span></div>
                                    <div className="text-sm flex flex-row items-center gap-1"><span className="hidden sm:block">Date:</span><span className="font-medium">{date}</span></div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div className="font-medium">₱{item.amount ?? 0}</div>
                                    <div className='group relative flex justify-center'>
                                        <img src={payment} alt={item.payment_method} className="h-[25px] mx-auto rounded-sm" />
                                        <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{item.payment_method}</span>
                                    </div>
                                </div>
                            </div>

                            <a href={item.payment_url} target="_blank" rel="noreferrer" className={`text-xs text-blue-500 text-center ${!item.payment_url && 'hidden'}`}>click here to pay</a>

                        </li>
                    })
                }
            </ul>
        </>
    );
}


function Large({ items, loading, error, }: any) {
    return (
        <div className="w-full px-10 py-5">
            <table className="table-fixed w-full divide-y divide-gray-300 bg-white shadow-md rounded-lg">
                <thead className="h-[60px] text-left text-white bg-blue-500">
                    <tr>
                        <th className="font-medium px-3 w-[30px] rounded-tl-lg"></th>
                        <th className="font-medium px-3 w-[100px]">Type</th>
                        <th className="font-medium px-3 w-[200px]">Date</th>
                        <th className="font-medium px-3">Name</th>
                        <th className="font-medium px-3 w-[80px] text-center">Status</th>
                        <th className="font-medium px-3 w-[100px] text-center">Plan</th>
                        <th className="font-medium px-3 w-[80px] text-center">Amount</th>
                        <th className="font-medium px-3 w-[100px] text-center">Payment</th>
                        <th className="font-medium px-3 w-[50px] text-center rounded-tr-lg"></th>
                    </tr>
                </thead>

                <tbody className={`divide-y divide-gray-200 ${!loading && 'hidden'}`}>
                    <tr className='h-[40px]'>
                        <td colSpan={8} className='text-center'>
                            <div className='w-full'><Spinner className='w-8 h-8 inline text-blue-500 fill-gray-400' /></div>
                        </td>
                    </tr>
                </tbody>

                <tbody className={`divide-y divide-gray-200 ${(loading || !error) && 'hidden'}`}>
                    <tr className='h-[40px]'>
                        <td colSpan={8} className='text-center'>
                            <div className='text-red-500 italic text-sm'><div dangerouslySetInnerHTML={{ __html: error }} /></div>
                        </td>
                    </tr>
                </tbody>

                <tbody className={`divide-y divide-gray-200 ${(loading || items.length > 0 || error) && 'hidden'}`}>
                    <tr className='h-[40px]'>
                        <td colSpan={8} className='text-center'>
                            <div className='italic text-sm'>No data</div>
                        </td>
                    </tr>
                </tbody>

                <tbody className={`divide-y divide-gray-200 ${(items.length === 0 || loading || error) && 'hidden'}`}>
                    {
                        items.map((item: any, index: number) => {

                            const date = item.date ? moment(item.date).format('YYYY-MM-DD @ hh:mm:ss a') : '';
                            const expiration = item.expiration ? moment(item.expiration).format('YYYY-MM-DD @ hh:mm:ss a') : '';

                            let payment: any;

                            switch (item.payment_method) {
                                case 'maya': payment = maya;
                                    break;
                                case 'gcash': payment = gcash;
                                    break;
                                case 'shopeepay': payment = shopee;
                                    break;
                                case 'grabpay': payment = grab;
                                    break;
                            }

                            let status = <FaRegQuestionCircle className="text-gray-500 h-[20px] w-[20px]" />;


                            switch (item.status) {
                                case 'CREATED':
                                    status = <IoAddCircle className="text-gray-500 inline h-[20px] w-[20px]" />;
                                    break;
                                case 'WAITING':
                                    status = <MdPending className="text-gray-500 inline h-[20px] w-[20px]" />;
                                    break;
                                case 'ERROR':
                                    status = <IoIosCloseCircle className="text-red-500 inline h-[20px] w-[20px]" />;
                                    break;
                                case 'SUCCEEDED':
                                    status = <FaCircleCheck className="text-green-500 inline h-[18px] w-[18px]" />;
                                    break;
                                case 'PENDING':
                                    status = <FaCircleCheck className="text-orange-500 inline h-[18px] w-[18px]" />;
                                    break;
                                case 'FAILED':
                                    status = <FaCircleExclamation className="text-red-500 inline h-[17px] w-[17px]" />;
                                    break;
                                case 'VOIDED':
                                    status = <SiVoidlinux className="text-gray-500 inline h-[18px] w-[18px]" />;
                                    break;
                                case 'REFUNDED':
                                    status = <IoArrowUndoCircle className="text-blue-500 inline h-[20px] w-[20px]" />;
                                    break;
                                case 'EXPIRED':
                                    status = <MdAccessTimeFilled className="text-gray-500 inline h-[20px] w-[20px]" />;
                                    break;
                            }

                            return <tr key={`${index}-item`} className={`h-[40px] hover:bg-blue-50`}>
                                <td className="whitespace-nowrap text-center">
                                    <div className='group relative flex justify-center'>
                                        <IoCopy size={15} className='text-blue-500 cursor-pointer active:text-black' onClick={() => navigator.clipboard.writeText(`${item.id}`)} />
                                        <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Click to copy Reference Id:<br /><span className='font-medium text-sm'>{item.id}</span></span>
                                    </div>
                                </td>
                                <td className="whitespace-nowrap px-3 text-xs">{item.action?.toUpperCase()}</td>
                                <td className="whitespace-nowrap px-3 text-xs">{date}</td>
                                <td className="whitespace-nowrap px-3"><a href={`/user/transaction/${item.id}`} className={`text-sm text-blue-500 hover:underline`}>{item.name}</a></td>
                                <td className="px-3 text-xs text-center">
                                    <div className='group relative flex justify-center'>
                                        {status}
                                        <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{(item.status === 'FAILED' || item.status === 'ERROR' ? item.failure ?? 'error' : item.status)?.toLowerCase()}</span>
                                    </div>
                                </td>
                                <td className="px-3 text-xs text-center">{item.quantity} {item.unit}{parseInt(item.quantity) > 1 ? 's' : ''}</td>
                                <td className="px-3 text-sm text-right font-medium">₱ {item.amount ?? 0}</td>
                                <td className="px-3 text-center">
                                    <div className='group relative flex justify-center'>
                                        <img src={payment} alt={item.payment_method} className="h-[25px] mx-auto rounded-sm" />
                                        <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{item.payment_method}</span>
                                    </div>
                                </td>
                                <td className="px-3 text-xs text-center">
                                    <a href={item.payment_url} target="_blank" rel="noreferrer" className={`text-blue-500 ${!item.payment_url && 'hidden'}`}><FaExternalLinkSquareAlt className="inline" size={15} /></a>
                                </td>
                            </tr>
                        })
                    }
                </tbody>

            </table>
        </div>
    )
}
