
import Header from '../../components/header'
import Footer from '../../components/footer'
import dev from '../../assets/dev.jpg';

export default function About() {
    return (
        <div className="flex flex-col h-screen">

            <Header className='flex' />

            <div className='flex-1 mb-5 md:my-10'>
                <div className='mx-auto w-full md:w-[1024px] px-5'>

                    <div className='text-center my-10'>
                        <h1 className='text-[30px] leading-tight'>About Us</h1>
                    </div>

                    <div className='text-center py-10 px-10 w-full md:w-[1024px] mx-auto bg-white rounded-2xl'>

                        <div>
                            <span className='text-2xl font-medium text-blue-500'>KPTD Network and Data Solution</span>
                        </div>

                        <div className='text-sm mt-5 leading-6'>We are committed to delivering cutting-edge network solutions designed to enhance connectivity and streamline operations for businesses. Our expertise in remote access services, VPN solutions, and Internet service provision makes us a trusted provider for your network and connectivity needs.</div>
                        <div className='text-sm mt-5 leading-6'>Founded with a vision to revolutionize the way businesses connect and operate, KPTD Network and Data Solution has grown into a reliable provider of essential network services. Our team combines technical excellence with a customer-centric approach to deliver solutions that drive efficiency and growth.</div>

                        <div className='mt-[100px]'>
                            <div>Contact Information:</div>
                            <div className='text-sm'>
                                <div className='mt-1'>
                                    <span className='text-xs'>Email:</span> <a href='mailto:support@kptd.ph' className='text-blue-500'>support@kptd.ph</a>
                                </div>
                                <div className='mt-1'>
                                    <span className='text-xs'>Address:</span> <span>Santiago City, Isabela, PH 3311</span>
                                </div>
                            </div>
                        </div>

                        <div className='mt-10'>
                            <div>Developers:</div>
                            <div className='mt-5 flex flex-row items-center justify-center gap-2'>
                                <a href='https://darv.dev' rel="noreferrer" target='_blank'>
                                    <img src={dev} alt='vin' className='h-[50px] w-[50px] rounded-full' />
                                    <div className='text-xs mt-2'>Darv</div>
                                </a>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
            <Footer />
        </div>
    )
}
