import Header from '../../components/header'
import Footer from '../../components/footer'
import { FaRegCheckCircle } from 'react-icons/fa'
import { IoInformationCircle } from 'react-icons/io5'
import gcash from '../../assets/gcash-logo.svg';
import maya from '../../assets/paymaya-logo.svg';
import shopee from '../../assets/shopeepay-logo-2.svg';
import grab from '../../assets/grabpay-logo.svg';
import { HiShieldCheck } from 'react-icons/hi';

export default function Pricing() {
  return (
    <div className="flex flex-col h-screen">
      <Header className='flex' />
      <div className='flex-1 my-10'>

        <div className='mx-auto w-full md:w-[1024px]'>

          <div className='text-center'>
            <h1 className='md:mt-10 text-[30px] leading-tight'>Choose a plan for your <span className='font-medium text-blue-600'>Remote Access</span></h1>
          </div>

          <div className='text-center mt-5 text-sm mb-3'>
            <div className='mb-3'>Supported payments:</div>
            <div className='flex flex-row items-center justify-center gap-2 mb-3'>

              <div className='group relative flex justify-center'>
                <img src={gcash} alt="GCASH" className='h-[30px]' />
                <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">GCash</span>
              </div>

              <div className='group relative flex justify-center'>
                <img src={maya} alt="MAYA" className='h-[30px]' />
                <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Maya</span>
              </div>

              <div className='group relative flex justify-center'>
                <img src={shopee} alt="SHOPEEPAY" className='h-[30px]' />
                <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">ShopeePay</span>
              </div>

              <div className='group relative flex justify-center'>
                <img src={grab} alt="GRABPAY" className='h-[30px]' />
                <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">GrabPay</span>
              </div>

            </div>
            <div><HiShieldCheck size={15} className='inline text-green-500' /> <span className='text-xs'>Our payment system is 100% secured</span></div>
          </div>

          <div className='mt-10 flex flex-col md:flex-row w-full items-center justify-center gap-10'>

            <div className='bg-white h-[380px] w-[380px] rounded-lg py-5 px-6 border-2 hover:border-blue-500 flex flex-col'>

              <div>
                <div>Demo Plan</div>
                <div className='text-xl my-3'><span className='text-[30px] font-medium'>Free</span></div>
              </div>

              <ul className='grow ml-2 flex flex-col gap-1 text-sm'>
                <li><FaRegCheckCircle className='inline text-green-500 mr-2' />Test Server</li>
                <li className='whitespace-nowrap flex flex-row items-center'><FaRegCheckCircle className='inline text-green-500 mr-2' />
                  <span>500Kbps bandwidth connection</span>

                  <div className='group relative flex justify-center'>
                    <IoInformationCircle size={15} className='ml-1 inline text-orange-500' />
                    <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">500Kbps is good for remote access</span>
                  </div>

                </li>
                <li><FaRegCheckCircle className='inline text-green-500 mr-2' />For winbox remote only (8291)</li>
                <li><FaRegCheckCircle className='inline text-green-500 mr-2' />1 demo per ip address</li>
                <li><FaRegCheckCircle className='inline text-green-500 mr-2' />No account required</li>
                <li><FaRegCheckCircle className='inline text-green-500 mr-2' />First 50 demo users concurrently</li>
                <li><FaRegCheckCircle className='inline text-green-500 mr-2' />Expires in 7 days</li>
              </ul>

              <div className='text-center mb-2'>
                <a href="/demo" className='bg-blue-600 hover:bg-black text-white rounded-full px-6 py-2'>Get Demo</a>
              </div>
            </div>

            <div className='bg-white h-[380px] w-[380px] rounded-lg py-5 px-6 border-2 hover:border-blue-500 flex flex-col'>
              <div>
                <div>Free Plan</div>
                <div className='text-xl my-3'><span className='text-[30px] font-medium'>Free</span></div>
              </div>

              <ul className='grow ml-2 flex flex-col gap-1 text-sm'>
                <li><FaRegCheckCircle className='inline text-green-500 mr-2' />Test Server</li>
                <li className='whitespace-nowrap flex flex-row items-center'><FaRegCheckCircle className='inline text-green-500 mr-2' />
                  <span>500Kbps bandwidth connection</span>

                  <div className='group relative flex justify-center'>
                    <IoInformationCircle size={15} className='ml-1 inline text-orange-500' />
                    <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">500Kbps is good for remote access</span>
                  </div>

                </li>
                <li><FaRegCheckCircle className='inline text-green-500 mr-2' />1 free remote per account</li>
                <li><FaRegCheckCircle className='inline text-green-500 mr-2' />Custom remote port</li>
                <li><FaRegCheckCircle className='inline text-green-500 mr-2' />Sign in required</li>
                <li><FaRegCheckCircle className='inline text-green-500 mr-2' />First 50 users (beta tester)</li>
                <li className='flex flex-row items-center'>
                  <FaRegCheckCircle className='inline text-green-500 mr-2' />
                  <span className='justify-center'>Server will removed once fully developed</span>
                </li>
              </ul>

              <div className='text-center mb-2'>
                <a href="/signin" className='bg-blue-600 hover:bg-black text-white rounded-full px-6 py-2'>Sign In</a>
              </div>

            </div>

          </div>


          <div className='mt-10 flex flex-col md:flex-row w-full items-center justify-center gap-10'>

            <div className='relative'>

              <div className='bg-white w-[380px] h-[380px] rounded-lg py-5 px-6 border-2 hover:border-blue-500 flex flex-col'>
                <div>
                  <div>Monthly Plan</div>
                  <div className='text-xl my-3'><span className='text-[30px] font-medium'>₱40</span>/mo</div>
                </div>

                <ul className='grow ml-2 flex flex-col gap-1 text-sm'>
                  <li><FaRegCheckCircle className='inline text-green-500 mr-2' />Cloud server</li>
                  <li><FaRegCheckCircle className='inline text-green-500 mr-2' />3Mbps bandwidth connection</li>
                  <li><FaRegCheckCircle className='inline text-green-500 mr-2' />Custom remote port</li>
                  <li><FaRegCheckCircle className='inline text-green-500 mr-2' />Sign in required</li>
                  {/* <div><FaRegCheckCircle className='inline text-green-500 mr-2' />Multiple port per ip address</div> */}
                  <li><FaRegCheckCircle className='inline text-green-500 mr-2' />Expires in 1 month (renewable)</li>
                  <li><FaRegCheckCircle className='inline text-green-500 mr-2' />Refundable within 24 hours</li>
                  <li><FaRegCheckCircle className='inline text-green-500 mr-2' />With support</li>
                </ul>

                <div className='text-center mt-10 mb-2'>
                  <a href="/signin" className='bg-blue-600 hover:bg-black text-white rounded-full px-6 py-2'>Sign In</a>
                </div>
              </div>

              {/* <div className='absolute inset-0 flex justify-center items-center rounded-lg bg-gray-300 bg-opacity-25 tracking-wide backdrop-blur-[1.5px] font-medium text-xl'>
                <div className='border-[2px] border-gray-500 rounded-xl px-4 py-[1px] text-black shadow-xl'>Available Soon...</div>
              </div> */}
            </div>

            <div className='relative'>

              <div className='bg-white w-[380px] h-[380px] rounded-lg py-5 px-6 border-2 border-black hover:border-blue-500 flex flex-col'>
                <div>
                  <div>Yearly Plan</div>
                  <div className='text-xl my-3'><span className='text-[30px] font-medium'>₱30</span>/mo</div>
                </div>

                <ul className='grow ml-2 flex flex-col gap-1 text-sm'>
                  <li><FaRegCheckCircle className='inline text-green-500 mr-2' />Cloud server</li>
                  <li><FaRegCheckCircle className='inline text-green-500 mr-2' />5Mbps bandwidth connection</li>
                  <li><FaRegCheckCircle className='inline text-green-500 mr-2' />Custom remote port</li>
                  <li><FaRegCheckCircle className='inline text-green-500 mr-2' />Sign in required</li>
                  {/* <div><FaRegCheckCircle className='inline text-green-500 mr-2' />Multiple port per ip address</div> */}
                  <li><FaRegCheckCircle className='inline text-green-500 mr-2' />Expires in 1 year (renewable)</li>
                  <li><FaRegCheckCircle className='inline text-green-500 mr-2' />Refundable within 24 hours</li>
                  <li><FaRegCheckCircle className='inline text-green-500 mr-2' />With support</li>
                </ul>

                <div className='text-center mt-10 mb-2'>
                  <a href="/signin" className='bg-black hover:bg-blue-500 text-white rounded-full px-6 py-2'>Sign In</a>
                </div>
              </div>

              {/* <div className='absolute inset-0 flex justify-center items-center rounded-lg bg-gray-300 bg-opacity-25 tracking-wide backdrop-blur-[1.5px] font-medium text-xl'>
                <div className='border-[2px] border-gray-500 rounded-xl px-4 py-[1px] text-black shadow-xl'>Available Soon...</div>
              </div> */}

            </div>

          </div>


        </div>
      </div>

      <Footer />
    </div>
  )
}
