import { useState } from "react";
import { errorMessage } from "../../utils/utils";
import Spinner from "../../components/spinner";
import { Bounce, toast, ToastContainer } from "react-toastify";
import api from "../../utils/api";

export default function PasswordReset() {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');

    const [error, setError] = useState('');

    async function sendReset(e: any) {
        e.preventDefault();

        setError('');
        setLoading(true);

        try {
            const res = await api.post(`/user/password/reset`, { email });

            const json = await res.json();
            if (res.ok) {
                toast.success(json.message);
            } else {
                setError(errorMessage(json));
            }
        } catch (err) {
            setError(errorMessage(err));
        }
        setLoading(false);
    }

    return (
        <>
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">

                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Reset Password
                    </h2>

                    <div className="text-center text-sm mt-5">Enter your email address below, and if the account exists, we’ll send you a link to reset your password.</div>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <form onSubmit={sendReset}>
                            <div className="mb-5">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                                <div className="mt-1">
                                    <input
                                        name="email"
                                        type="email"
                                        required
                                        value={email}
                                        disabled={loading}
                                        autoComplete="email"
                                        placeholder="name@domain.com"
                                        className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-sm ring-gray-300 placeholder:text-gray-400"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>

                            {error && <div className="text-center text-red-500 italic text-sm mb-5"><div dangerouslySetInnerHTML={{ __html: error }} /></div>}

                            <div className="mt-10">
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="disabled:bg-gray-500 flex flex-row items-center w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                                    {loading && <Spinner className="mr-2" />} Send
                                </button>
                            </div>

                        </form>

                    </div>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        <a href="/signin" className="font-semibold leading-6 text-blue-600 hover:text-black">Sign In</a>
                    </p>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        <a href="/" className="font-semibold leading-6 text-black hover:text-blue-600">
                            Go Back To Home
                        </a>
                    </p>

                </div>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />
        </>
    )
}
