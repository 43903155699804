

function arrayBufferToBase64(buffer: ArrayBuffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}


function errorMessage(error: any): string {

    console.log('error.message', error.message);
    console.log('error.error', error.error);
    console.log('error', error);

    let message: string | undefined;

    if (typeof error.message === 'string') {
        message = error.message;
    }

    if (error.message === 'Unexpected end of JSON input') {
        return 'Server error';
    }

    if (typeof error.error === 'string') {
        return error.error;
    }

    if (error.error) {
        return error.error.toString();
    }

    if (message) {
        return message;
    }
    
    return error.toString();
}

export { arrayBufferToBase64, errorMessage };