


import { useEffect, useState } from 'react'
import Loader from '../../../components/loader';
import UserNav from '../components/userNav';
import UserHeader from '../components/userHeader';
import { errorMessage } from '../../../utils/utils';
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { IoIosWarning } from "react-icons/io";
import { Bounce, toast, ToastContainer } from 'react-toastify';
import { IoSend } from 'react-icons/io5';
import Spinner from '../../../components/spinner';
import api from '../../../utils/api';
import { FaArrowRightLong } from 'react-icons/fa6';
import moment from 'moment';
import { CiCalendarDate } from 'react-icons/ci';
import { MdLabelOutline } from 'react-icons/md';
import { GrAnnounce } from "react-icons/gr";

export default function UserDashboard() {

  const [pageLoading, setPageLoading] = useState(true);
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>();

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogStatus, setDialogStatus] = useState('');

  const [news, setNews] = useState<any[]>([]);
  const [newsLoading, setNewsLoading] = useState(true);
  const [newsError, setNewsError] = useState('');

  const [verifyLoading, setVerifyLoading] = useState(false);

  useEffect(() => {
    const token = window.localStorage.token;

    if (!token) {
      window.location.replace('/signin');
      return;
    }

    setName(window.localStorage.name ?? window.localStorage.email ?? 'user');

    setPageLoading(false);

    getUser();
    getNews();

  }, []);

  const [nav, setNav] = useState(false);

  async function verifyEmail() {

    setVerifyLoading(true);

    try {
      const res = await api.post(`/auth/email/verify`);
      const json = await res.json();
      if (res.ok) {
        setOpenDialog(false);
        toast.success(json.message);
      } else {
        toast.error(errorMessage(json));
      }
    } catch (error) {
      toast.error(errorMessage(error));
    }

    setVerifyLoading(false);
  }

  async function getUser() {
    try {
      const res = await api.get(`/user`);
      const json = await res.json();
      if (res.ok) {
        setUser(json);
        if (json.email_verified !== true) {
          setDialogStatus('warning');
          setDialogMessage(`
            <b>Your email address has not been verified yet</b><br /><br />To activate your account, please check your inbox and follow the verification link we sent you. If you don't see the email, check your spam folder or click <b>Send Email Verification</b> again.
            `);
          setOpenDialog(true);
        }
      } else {
        setError(errorMessage(json));
      }

    } catch (err) {
      setError(errorMessage(err));
    }

    setLoading(false);
  }

  async function getNews() {
    try {

      const res = await api.get('/apps/news?type=remote');
      const json = await res.json();
      if (res.ok) {
        setNews(json);
      } else {
        setNewsError(errorMessage(json));
      }

    } catch (err) {
      setNewsError(errorMessage(error));
    }

    setNewsLoading(false);
  }


  if (pageLoading) {
    return (<div className='w-screen h-screen text-center flex flex-col bg-white justify-center'><Loader /></div>)
  }

  return (
    <>
      <div className='flex flex-row'>

        <div className='hidden xl:block'><UserNav nav='dashboard' className='fixed block left-[0px]' /></div>

        <main className='xl:ml-[250px] grow w-screen flex flex-col'>
          <UserHeader title='Dashboard' setNav={() => { setNav(!nav) }} />

          <section className='grow'>

            <div className='text-lg m-5'>Hello, <span className='font-medium'>{name}</span>. Welcome to your dashboard!</div>

            <div className={`text-center ${!loading && 'hidden'}`}><Loader /></div>

            <div className={`text-center italic text-sm text-red-500 ${(loading || !error) && 'hidden'}`}><div dangerouslySetInnerHTML={{ __html: error }} /></div>

            <div className={`flex flex-col gap-2 mx-5 ${(loading || error || !user) && 'hidden'}`}>

              <div><a href="/user/remote" className='text-blue-700 flex flex-row items-center gap-2 underline'><FaArrowRightLong />Go To Remote Access</a></div>
              <div><a href="/user/transaction" className='text-blue-700 flex flex-row items-center gap-2 underline'><FaArrowRightLong />Go To Transaction List</a></div>

            </div>

            <div className='flex flex-col mx-5'>

              <div className='mt-5 font-medium text-xl flex flex-row items-center gap-2'>News & Updates <GrAnnounce /></div>

              <div className={`text-center my-10 ${!newsLoading && 'hidden'}`}><Loader /></div>
              <div className={`text-center my-10 italic text-sm text-red-500 ${(newsLoading || !newsError) && 'hidden'}`}><div dangerouslySetInnerHTML={{ __html: newsError }} /></div>

              <ul className='sm:mx-5 mt-5 flex flex-col gap-5 mb-5'>{ news?.map((item: any, index: number) => {

                const date = item.date_created ? moment(item.date_created).format('YYYY-MM-DD @ hh:mm:ss a') : '';

                return <li key={`${index}-news`} className='flex flex-col'>
                  <div className='font-medium flex flex-row items-center gap-2 text-blue-500'><MdLabelOutline /><div dangerouslySetInnerHTML={{ __html: item.title }} /></div>
                  <div className='text-sm flex flex-row items-center gap-2'><CiCalendarDate />{date}</div>
                  <div className='mt-1 text-base' dangerouslySetInnerHTML={{ __html: item.message }} />
                  <div className='flex flex-row items-center gap-2'>
                    <div className='text-xs'>Created By:</div><div className='font-medium text-sm'>{item.created_by}</div>
                  </div>
                </li>
              }) }</ul>
            </div>

          </section>

        </main>
      </div>

      <Dialog open={openDialog} onClose={() => { }} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">

                  {dialogStatus === 'ok' && <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-200 sm:mx-0 sm:h-10 sm:w-10">
                    <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-700" />
                  </div>}

                  {dialogStatus === 'error' && <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-200 sm:mx-0 sm:h-10 sm:w-10">
                    <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-700" />
                  </div>}

                  {dialogStatus === 'warning' && <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-200 sm:mx-0 sm:h-10 sm:w-10">
                    <IoIosWarning aria-hidden="true" className="h-6 w-6 text-orange-700" />
                  </div>}

                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">

                    {/* <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">Demo Sent</DialogTitle> */}

                    <div className="mt-2">
                      <div className="text-sm text-gray-700"><div dangerouslySetInnerHTML={{ __html: dialogMessage }} /></div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 flex flex-row gap-3">
                <div className='grow'></div>
                {user?.email_verified !== true && <button className='disabled:bg-gray-500 flex flex-row items-center bg-blue-600 px-3 py-1 text-sm font-medium rounded-md text-white shadow-sm hover:bg-blue-500 active:bg-blue-700' disabled={verifyLoading} onClick={verifyEmail}>
                  {verifyLoading ? <Spinner className='inline mr-2' /> : <IoSend className='inline mr-2' />}Send Email Verification
                </button>}
                <button
                  type="button"
                  data-autofocus
                  disabled={verifyLoading}
                  onClick={() => setOpenDialog(false)}
                  className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Close
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />


      <div className='xl:hidden'>
        {nav && <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0" onClick={() => setNav(false)}></div>}
        <div className={nav ? "fixed top-0 left-0 w-[250px] h-screen bg-white z-10 duration-300" : "fixed top-0 left-[-100%] w-[250px] h-screen bg-white z-10 duration-300"}>
          <UserNav nav='dashboard' />
        </div>
      </div>

    </>
  )

}