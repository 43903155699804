import { useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import ReactPlayer from 'react-player/youtube'
import Spinner from "../../../components/spinner";
import TutorialNav from "../components/tutorial_nav";
import { IoArrowBackCircle } from "react-icons/io5";

export default function HowToConnectL2tpClient() {

    const [loading, setLoading] = useState(true);

    return (
        <>
            <div className="flex flex-col h-screen">
                <Header className='flex' />
                <div className="flex-1 mb-5 lg:my-10 w-full">
                    <div className='mx-auto w-full lg:max-w-[1024px] px-5'>

                        <div className='mx-auto text-center mx-auto w-full lg:w-[500px] mb-10'>
                            <h1 className='mt-10 mb-2 text-[30px] leading-tight'>Tutorials</h1>
                            <p>Get started with our service through simple, guided tutorials and make the most of its features.</p>
                        </div>

                        <div className="mx-auto bg-white rounded-xl p-8 flex flex-row gap-5">

                            <TutorialNav name='l2tp' />

                            <div className="hidden lg:block h-screen min-h-full w-[0.5px] self-stretch bg-gray-300"></div>

                            <div className="grow lg:ml-5">

                                <div className="flex flex-row items-center gap-3">
                                    <IoArrowBackCircle size={30} className="lg:hidden text-blue-500 cursor-pointer"
                                        onClick={() => {
                                            window.location.replace('/tutorials');
                                        }}
                                    />
                                    <h1 className="text-xl font-bold">Configuring L2TP Client on MikroTik</h1>
                                </div>

                                <ul className="list-decimal ml-10 mt-8 flex flex-col gap-3">

                                    <li>
                                        <div className="font-bold">Add L2TP Client Interface:</div>
                                        <ul className="list-disc pl-8">
                                            <li>Go to PPP {">"} Interface.</li>
                                            <li>Click + and select L2TP Client.</li>
                                        </ul>
                                    </li>

                                    <li>
                                        <div className="font-bold">Configure L2TP Client Settings:</div>
                                        <ul className="list-disc pl-8">
                                            <li><span className="font-medium italic">Name</span>: Enter a name for the L2TP client interface.</li>
                                            <li><span className="font-medium italic">Connect To</span>: Enter the L2TP server's IP address or hostname</li>
                                            <li><span className="font-medium italic">User</span>: Enter the username provided for the L2TP connection.</li>
                                            <li><span className="font-medium italic">Password</span>: Enter the corresponding password.</li>
                                            <li><span className="font-medium italic">Profile</span>: <b>default-encryption</b></li>
                                            <li><span className="font-medium italic">Keepalive Timeout</span>: <b>60</b></li>
                                            <li><span className="font-medium italic">Src. Address:</span>: (leave empty)</li>
                                            <li><span className="font-medium italic">Use Peer DNS</span>: <b>no</b></li>
                                            <li><span className="font-medium italic">Use IPsec</span>: (Unchecked)</li>
                                            <li><span className="font-medium italic">Allow Fast Path</span>: (Unchecked)</li>
                                            <li><span className="font-medium italic">Dial On Demand</span>: (Unchecked)</li>
                                            <li><span className="font-medium italic">Add Default Route</span>: (Unchecked)</li>
                                        </ul>
                                    </li>

                                    <li>
                                        <div className="font-bold">Apply and Enable the Interface:</div>
                                        <ul className="list-disc pl-8">
                                            <li>Click <span className="font-bold">Apply</span> and <span className="font-bold">OK</span> to save your settings.</li>
                                            <li>Ensure the interface is enabled and connected.</li>
                                        </ul>
                                    </li>

                                </ul>


                                <div className="my-10 relative bg-gray-900 h-[360px] w-full">

                                    <div className="w-full h-[360px]">
                                        <ReactPlayer style={{ margin: '0px auto' }} width='100%' /*height={505} width={853}*/ onReady={() => {
                                            setLoading(false);
                                        }} controls={true} url='https://youtu.be/0R4wjWgidhU' />
                                    </div>
                                    {loading && <div className="absolute inset-0 flex justify-center items-center w-full h-[360px]"><Spinner className='h-[50px] w-[50px] text-white' /></div>}
                                </div>


                            </div>

                        </div>

                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}
