import { AiOutlineFacebook } from "react-icons/ai";
import { CiYoutube } from "react-icons/ci";

export default function Footer() {
    return (
        <div className="bg-white w-full ring-1 ring-gray-900/5">
            <footer className="mx-auto flex bg-white md:w-[1024px] shadow-sm flex-col">

                <div className="mx-auto px-10 h-[60px] w-full md:w-[1024px] items-center flex flex-row text-sm text-gray-600 justify-center">
                    <div className="text-center">
                        <div className="flex flex-row gap-3 text-xs ml-3 text-blue-600 font-medium justify-center md:justify-start">
                            <a href="/terms-and-conditions">Terms and Conditions</a>
                            <a href="/privacy-policy">Privacy Policy</a>
                            <a href="/about">About Us</a>
                        </div>
                        <div className="text-xs mt-[3px]">© 2024 KPTD Network and Data Solution. All rights reserved</div>
                    </div>

                    <div className="hidden md:block grow">
                        <div className="flex flex-row gap-3 items-center">
                            <span className="grow"></span>
                            <a href="https://www.facebook.com/kptd.ph" target="_blank" rel="noreferrer"><AiOutlineFacebook size={25} /></a>
                            <a href="https://www.youtube.com/@kptd-ph" target="_blank" rel="noreferrer"><CiYoutube size={30} /></a>
                        </div>
                    </div>
                </div>

                <div className="md:hidden w-full">
                    <div className="mb-5 flex flex-row items-center justify-center gap-3 text-gray-600">
                        <a href="https://www.facebook.com/kptd.ph" target="_blank" rel="noreferrer"><AiOutlineFacebook size={25} /></a>
                        <a href="https://www.youtube.com/@kptd-ph" target="_blank" rel="noreferrer"><CiYoutube size={30} /></a>
                    </div>
                </div>

            </footer>
        </div>
    )
}
