import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './index.css';

import Main from './main/main';
import Demo from './pages/demo/demo';
import SignIn from './pages/signin/signin';
import SignUp from './pages/signup/signup';
import Pricing from './pages/pricing/pricing';
import Servers from './pages/servers/servers';
import Contact from './pages/contact/contact';
import TermsAndConditions from './pages/terms-and-conditions/terms-and-conditions';
import PrivacyPolicy from './pages/privacy-policy/privacy-policy';
import About from './pages/about/about';

import Tutorials from './pages/tutorials/tutorials';
import HowToConnectL2tpClient from './pages/tutorials/how-to-connect-l2tp-client/how-to-connect-l2tp-client';
import HowToConnectSstpClient from './pages/tutorials/how-to-connect-sstp-client/how-to-connect-sstp-client';
import HowToConnectOvpnClient from './pages/tutorials/how-to-connect-ovpn-client/how-to-connect-ovpn-client';
import HowToRemotelyAccessMikroTikUsingWinbox from './pages/tutorials/how-to-remotely-access-mikrotik-using-winbox/how-to-remotely-access-mikrotik-using-winbox';
import HowToInstallOvpnClientOnAdopisoft from './pages/tutorials/how-to-install-ovpn-client-on-adopisoft/how-to-install-ovpn-client-on-adopisoft';

import PasswordReset from './pages/password-reset/password-reset';
import User from './pages/user/user';
import UserDashboard from './pages/user/dashboard/dashboard';
import UserRemote from './pages/user/remote/remote';
import RemoteItem from './pages/user/remote/remote-item/remote-item';
import RemoteNew from './pages/user/remote/remote-new/remote-new';
import UserTransaction from './pages/user/transaction/transaction';
import UserTransactionItem from './pages/user/transaction/transaction-item';

import PlainNotFound from './pages/404/404_plain';

import Payment from './pages/payment/payment';

import NotFound from './pages/404/404';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  // </React.StrictMode>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="demo" element={<Demo />} />
      <Route path="signin" element={<SignIn />} />
      <Route path="signup" element={<SignUp />} />
      <Route path="pricing" element={<Pricing />} />
      <Route path="servers" element={<Servers />} />
      <Route path="contact" element={<Contact />} />
      <Route path="password-reset" element={<PasswordReset />} />
      <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="about" element={<About />} />

      <Route path="tutorials">
        <Route path="" element={<Tutorials />} />
        <Route path="how-to-connect-l2tp-client" element={<HowToConnectL2tpClient />} />
        <Route path="how-to-connect-sstp-client" element={<HowToConnectSstpClient />} />
        <Route path="how-to-connect-ovpn-client" element={<HowToConnectOvpnClient />} />
        <Route path="how-to-remotely-access-mikrotik-using-winbox" element={<HowToRemotelyAccessMikroTikUsingWinbox />} />
        <Route path="how-to-install-ovpn-client-on-adopisoft" element={<HowToInstallOvpnClientOnAdopisoft />} />
      </Route>
      
      <Route path='user'>
        <Route path="" element={<User />} />
        <Route path="dashboard" element={<UserDashboard />} />
        <Route path="remote" element={<UserRemote />} />
        <Route path="remote/new" element={<RemoteNew />} />
        <Route path="remote/:id" element={<RemoteItem />} />
        <Route path="transaction" element={<UserTransaction />} />
        <Route path="transaction/:id" element={<UserTransactionItem />} />
        <Route path="*" element={<PlainNotFound />} />
      </Route>

      <Route path='payment' element={<Payment />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);